/* stylelint-disable no-descending-specificity */
.block-six_blocks {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .column-header {
    p {
      margin: 0 0 22px;
    }
  }

  .box-item {
    margin-bottom: 27px;

    .box-item-outer {
      display: flex;
      width: 100%;
      height: 100%;
      box-shadow: 0 3px 76px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
    }

    .box-item-inner {
      width: 100%;
      height: 100%;
    }

    .box-item-image {
      img {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .box-item-content {
      padding: 30px 35px 25px;

      @include from($desktop) {
        padding: 30px 45px 25px;
      }

      @include from($widescreen) {
        padding: 30px 45px 30px;
      }
    }
  }
}
