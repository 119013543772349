/* stylelint-disable no-descending-specificity */
.block-four_blocks {
  a.block-image {
    z-index: 3;
    border-radius: 10px;
    transition: all 0.2s linear;
    overflow: hidden;

    img {
      width: 100%;
      transition: all 0.2s linear;
    }

    .bgr-overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(rgba(49, 44, 40, 0) 0%, $main-color 100%);
      opacity: 0.47;
      border-radius: 10px;
      z-index: 1;
      transition: all 0.2s linear;
    }

    .block-image-title {
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 1.1em;
      padding: 0 30px 40px;
      width: 100%;
      overflow-wrap: break-word;
      z-index: 3;
    }

    &:hover .bgr-overlay {
      opacity: 0.94;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
}
