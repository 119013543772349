/* Variable changes go here */

// Colors
$main-color: #2b1b0d;
$second-color: #d37700;
$link: #d37700;
$third-color: #be562c;
$fourth-color: #f8e1d7;
$fifth-color: #e8efd3;
$sixth-color: #cbb293;
$seventh-color: #190e07;
$eight-color: #de4826;
$ninth-color: #f7b30e;
$hover-color: #646464;
$text-color: $main-color;
$accent-color: #fbf5ef;
$white-color: #fff;
$black-color: #000;

// Fonts
$font-tagline: 'Didot', sans-serif;
$font-title: 'Frank Ruhl Libre', serif;
$font-text: 'Montserrat', sans-serif;
$font-cask: 'Playfair Display', serif;
$font-bodega: 'condor-compressed', sans-serif;
$family-sans-serif: 'Montserrat', sans-serif;
$font-family-base: 'Montserrat', sans-serif;

// Other
$container-width: 1635px;

$fullhd: 1450px + (2 * $gap);

$input-placeholder-color:#ccc;

.has-text-gold {
  color: $sixth-color;
}
