/* stylelint-disable no-descending-specificity */
.block-text_picture {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;

    /* .container.is-fluid {
      padding-left: 0;
      padding-right: 0;
    } */

    .text-eyebrow {
      margin: 0 0 15px;

      @include from($desktop) {
        margin: 0 0 20px;
      }
    }

    .h2 {
      margin: 0 0 24px;

      @include from($desktop) {
        margin: 0 0 31px;
      }
    }

    .columns {
      .columns {
        .column:first-child {
          @include from($desktop) {
            padding-right: 30px;
          }

          @include from($widescreen) {
            padding-right: 30px;
          }
        }
      }
    }
  }
}
