/* stylelint-disable no-descending-specificity */
.download-list-section {
  padding-top: 45px;
  padding-bottom: 45px;

  @include from($desktop) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .h2,
  .h4 {
    margin: 0 0 25px;

    @include from($tablet) {
      margin: 0 0 39px;
    }
  }

  ul {
    li {
      display: block;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    li a {
      width: 100%;
      font-size: 14px;
      line-height: 1.4em;
      letter-spacing: 0.075em;
      position: relative;
      padding: 5px 45px 5px 0;

      @include from($widescreen) {
        font-size: 16px;
      }

      .download-link-file-type {
        margin-right: 13px;

        svg {
          height: 20px !important;
        }
      }

      .download-link-text {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: $main-color;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s linear;
        }
      }

      .download-link-icon {
        width: 39px;
        height: 39px;
        position: absolute;
        right: 0;

        svg {
          height: 18px !important;
        }
      }

      svg {
        display: block;
      }

      &:hover .download-link-text::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
