.blog-post-section {
  padding-top: 0;
  padding-bottom: 40px;

  @include from($tablet) {
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-bottom: 110px;
  }

  h3 {
    a {
      color: $main-color;

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }
    }
  }
}

.blog-post-content h2 {
  margin: 40px 0 20px;
  font-size: 28px;
  line-height: 1em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 28px;
    line-height: 1em;
  }

  @include from($fullhd) {
    font-size: 38px;
    line-height: 1em;
  }
}

.blog-post-content h3 {
  margin: 30px 0 15px;
  font-size: 24px;
  line-height: 1em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 25px;
    line-height: 1em;
  }

  @include from($fullhd) {
    font-size: 32px;
    line-height: 1em;
  }
}

.blog-post-content h4 {
  font-size: 20px;
  line-height: 1em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 22px;
    line-height: 1em;
  }

  @include from($fullhd) {
    font-size: 26px;
    line-height: 1em;
  }
}
