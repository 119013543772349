/* stylelint-disable no-descending-specificity */
.hero-meet-the-maker {
  z-index: 32;
  padding: 0;

  .img-wrap {
    img {
      display: block;
      object-fit: cover;
      object-position: top center;
      height: 360px;

      @include from($tablet) {
        height: 440px;
      }

      @include from($widescreen) {
        height: 480px;
      }

      @include from($fullhd) {
        height: 526px;
      }
    }
  }

  .hero-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: rgba(43, 27, 13, 0.43);
    padding: 20px 1.5rem;

    @include from($desktop) {
      padding: 20px 3rem;
    }

    .text-eyebrow {
      margin: 0 0 13px;
    }

    .h1,
    .h4 {
      margin: 0;
    }

    .h4 {
      margin: 0 0 5px;
    }

    .hero-box-social {
      .social-icons-rounded {
        margin-left: 10px;

        @include from($tablet) {
          margin-left: 20px;
        }

        li {
          margin-left: 5px;

          @include from($tablet) {
            margin-left: 12px;
          }
        }

        li a {
          width: 30px;
          height: 30px;
          border: 1px solid $white-color;

          @include from($tablet) {
            width: 46px;
            height: 46px;
          }

          svg {
            path {
              fill: $white-color;
            }
          }

          &:hover {
            border: 1px solid $white-color;
            background-color: $white-color;
          }

          &:hover svg path {
            fill: $main-color;
          }
        }
      }
    }
  }
}

.meet-the-maker-section-content {
  padding-top: 45px;
  padding-bottom: 60px;

  @include from($tablet) {
    padding-top: 55px;
    padding-bottom: 90px;
  }

  @include from($widescreen) {
    padding-top: 75px;
    padding-bottom: 145px;
  }

  .column-content {
    margin-bottom: 30px;

    @include from($tablet) {
      margin-bottom: 0;
    }

    img {
      display: block;
      border-radius: 10px;
      margin: 30px 0;

      @include from($tablet) {
        margin: 40px 0;
      }

      @include from($widescreen) {
        margin: 50px 0;
      }
    }
  }

  .column-sidebar {
    .sidebar-box-form {
      margin-bottom: 25px;
    }

    .sidebar-box-link-more {
      margin-top: 35px;

      @include from($widescreen) {
        margin-top: 55px;
      }
    }
  }
}

.bodega-maker-section {
  padding-top: 40px;
  padding-bottom: 55px;

  @include from($tablet) {
    padding-top: 45px;
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-top: 45px;
    padding-bottom: 95px;
  }

  .bodega-maker-img {
    img {
      display: block;
      border-radius: 10px;
      margin-bottom: 20px;

      @include from($widescreen) {
        margin-bottom: 30px;
      }

      @include from($widescreen) {
        margin-bottom: 50px;
      }
    }

    .social-icons-rounded {
      li {
        margin-right: 7px;

        @include from($widescreen) {
          margin-right: 15px;
        }

        &:nth-last-child(2) {
          padding-left: 7px;
          border-left: 1px solid $sixth-color;

          @include from($widescreen) {
            padding-left: 15px;
          }
        }
      }

      li a {
        width: 32px;
        height: 32px;

        @include from($widescreen) {
          width: 47px;
          height: 47px;
        }

        svg {
          height: 12px !important;

          @include from($widescreen) {
            height: 17px !important;
          }
        }
      }
    }
  }

  .bodega-maker-contact {
    .bodega-contact {
      .h5 {
        margin: 0 0 25px;

        @include from($desktop) {
          margin: 0 0 30px;
        }
      }
    }

    .bodega-content {
      address {
        .g-map {
          margin-bottom: 20px;

          @include from($widescreen) {
            margin-bottom: 0;
          }
        }
      }
    }

    .bodega-brands {
      padding: 0;
    }
  }
}
