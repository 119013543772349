/* stylelint-disable no-descending-specificity */
.block-campaign_promo {
  .block-campaign-promo-header {
    margin-bottom: 40px;

    @media only screen and (max-width: 767px) {
      text-align: center;
    }

    @include from($widescreen) {
      margin-bottom: 50px;
    }

    img {
      margin: 0 auto 15px;
      border-radius: 0;

      @include from($tablet) {
        margin: 0;
      }
    }

    .text-eyebrow {
      margin: 0 0 5px;
    }

    .button {
      padding-left: 10px !important;
      padding-right: 10px !important;
      background-color: $eight-color !important;

      @include from($tablet) {
        width: 100% !important;
      }

      &:hover {
        background-color: $third-color !important;
      }
    }
  }

  .block-campaign-promo-content {
    img {
      margin-bottom: 15px;
    }

    .h4 {
      margin: 0 0 5px;
    }

    p {
      margin: 0 0 25px;

      @include from($widescreen) {
        margin: 0 0 35px;
      }
    }

    .box-link {
      display: inline-block;
      font-size: 16px;
      line-height: 1.25em;
      font-weight: 500;
      color: $main-color;
      position: relative;
      //padding-left: 27px;
      transition: all 0.2s linear;

      @include from($tablet) {
        font-size: 22px;
        //padding-left: 42px;
      }

      @include from($fullhd) {
        font-size: 26px;
        //padding-left: 50px;
      }

      //&::before {
      //  content: '';
      //  position: absolute;
      //  top: 4px;
      //  left: 0;
      //  width: 15px;
      //  height: 11px;
      //  background-image: url(../images/icon-arrow-right-red-secondary.svg);
      //  background-size: 15px 11px;
      //  background-repeat: no-repeat;
      //
      //  @include from($tablet) {
      //    top: 6px;
      //    width: 23px;
      //    height: 16px;
      //    background-size: 23px 16px;
      //  }
      //
      //  @include from($fullhd) {
      //    top: 8px;
      //    width: 26px;
      //    height: 18px;
      //    background-size: 26px 18px;
      //  }
      //}

      &:hover {
        color: $eight-color;
      }
    }
  }

  .section.gradient {
    background: linear-gradient(#ffeee6 0%, #fff5f0 89.66%, #fffbf9 100%, #fff 100%);
  }
}
