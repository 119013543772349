/* stylelint-disable no-descending-specificity */
.block-news {
  .box-items {
    .column {
      margin-bottom: 15px;

      .box-item {
        border-bottom: 1px solid #f1e7db;
        padding-bottom: 25px;
        height: 100%;

        @include from($desktop) {
          padding-bottom: 35px;
        }

        .box-item-content {
          .text-eyebrow {
            margin: 0 0 5px;
            font-weight: 400;

            @include from($fullhd) {
              font-size: 13px;
            }
          }

          .h5 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;

            a {
              &:hover {
                // text-decoration: underline;
                color: $hover-color;
              }
            }
          }
        }

        .box-item-image {
          a {
            display: block;
            width: 61px;
            height: 61px;

            @include from($desktop) {
              width: 92px;
              height: 92px;
            }

            img {
              display: block;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
