.custom-tabs-section {
  background-color: rgba(203, 178, 147, 0.09);
  padding-top: 45px;
  padding-bottom: 45px;

  @include from($tablet) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .tabs-wrap {
    .tab-box {
      display: none;

      &.active {
        display: block;
      }
    }

    .select-tab {
      margin-bottom: 20px;

      @include from($tablet) {
        margin-bottom: 30px;
      }

      @include from($widescreen) {
        margin-bottom: 45px;
      }

      li {
        margin: 10px;
      }

      li a {
        display: inline-block;
        font-size: 13px;

        @include from($widescreen) {
          font-size: 15px;
        }

        line-height: 1.3em;
        font-weight: 600;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        color: rgba(43, 27, 13, 0.23);
        position: relative;
        transition: all 0.2s linear;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          margin: 0 auto;
          width: 0;
          height: 2px;
          background-color: #d8902b;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s linear;
        }

        &:hover,
        &.active {
          color: $main-color;
        }

        &:hover::before,
        &.active::before {
          left: 0;
          width: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
