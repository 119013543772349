.filter-menu {
  margin-bottom: 45px;

  ul {
    li {
      margin: 10px 11px;

      @include from($tablet) {
        margin: 10px 14px;
      }

      @include from($widescreen) {
        margin: 10px 21px;
      }
    }

    li a {
      display: inline-block;
      font-size: 13px;

      @include from($fullhd) {
        font-size: 16px;
      }

      line-height: 1.3em;
      font-weight: 600;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      color: rgba(43, 27, 13, 0.23);
      position: relative;
      transition: all 0.2s linear;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        margin: 0 auto;
        width: 0;
        height: 2px;
        background-color: #d8902b;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s linear;
      }

      &:hover,
      &.active {
        color: $main-color;
      }

      &:hover::before,
      &.active::before {
        left: 0;
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
