
@font-face {
  font-family: 'Didot';
  src: url('../fonts/Didot.woff2') format('woff2'), url('../fonts/Didot.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Didot';
  src: url('../fonts/Didot-Bold.woff2') format('woff2'), url('../fonts/Didot-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BodegaSans';
  src: url('../fonts/BodegaSansMedium.woff2') format('woff2'), url('../fonts/BodegaSansMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BodegaSans';
  src: url('../fonts/BodegaSansBlack.woff2') format('woff2'), url('../fonts/BodegaSansBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
