/* stylelint-disable no-descending-specificity */
.blk-navigation {
  padding-top: 35px;
  padding-bottom: 35px;

  .container {
    width: calc(100% - 3rem);
  }

  .blk-navigation-wrap {
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 13px;
      line-height: 1em;
      color: $seventh-color;
      transition: all 0.2s linear;

      @include from($tablet) {
        font-size: 15px;
      }

      @include from($fullhd) {
        font-size: 16px;
      }

      svg {
        width: 20px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;

        @include from($tablet) {
          width: 26px;
          height: 18px;
        }

        line,
        path {
          transition: all 0.2s linear;
        }
      }

      &.blk-navigation-prev {
        svg {
          margin-right: 10px;

          @include from($tablet) {
            margin-right: 15px;
          }
        }
      }

      &.blk-navigation-next {
        svg {
          margin-left: 10px;

          @include from($tablet) {
            margin-left: 15px;
          }
        }
      }

      &:hover {
        text-decoration: underline;

        svg line,
        svg path {
          stroke: $seventh-color !important;
        }
      }
    }
  }
}
