.block-centered_text {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;

    /* .container.is-fluid {
      padding-left: 0;
      padding-right: 0;
    } */

    p {
      margin: 0 0 26px;
    }

    .button {
      margin-top: 26px;
      background: $second-color !important;

      &:hover {
        background-color: $main-color !important;
      }
    }
  }
}
