/* stylelint-disable no-descending-specificity */
.modal {
  .modal-background {
    background-color: rgba(25, 14, 7, 0.74);
  }

  .modal-content {
    padding: 0 85px;
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    @include from($tablet) {
      padding: 0 100px;
    }

    .modal-content-holder {
      img {
        display: block;
        margin: 0 auto 15px;
        border: 1px solid #e6e6e6;
      }

      p {
        font-size: 12px;

        @include from($tablet) {
          font-size: 13px;
        }
      }
    }

    .modal-content-prev,
    .modal-content-next {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 48px;
      height: 48px;
      border: 1px solid $white-color;
      border-radius: 50%;
      background: transparent;
      padding: 0;
      cursor: pointer;
      transition: all 0.2s linear;

      @include from($tablet) {
        width: 64px;
        height: 64px;
      }

      svg {
        display: block;
        width: 21px !important;
        height: 13px !important;

        @include from($tablet) {
          width: 26px !important;
          height: 18px !important;
        }
      }

      &:focus {
        outline: 0;
      }

      &:hover {
        border: 1px solid $main-color;
        background: $main-color;
      }
    }

    .modal-content-prev {
      left: 20px;
    }

    .modal-content-next {
      right: 20px;
    }
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: none;

  &.active {
    display: block;
  }

  .modal-video-wrap {
    position: relative;
    z-index: 7;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(25, 14, 7, 0.84);
      z-index: 6;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $white-color;
    background: transparent;
    text-align: center;
    font-size: 26px;
    line-height: 46px;
    font-weight: 500;
    color: $white-color;
    z-index: 999;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      border: 1px solid $white-color;
      background: $white-color;
      color: $main-color;
    }
  }

  .modal-video-container {
    position: relative;
    z-index: 32;
    max-width: 990px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 1.5rem;

    .responsive-object {
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
    }
  }
}
