/* stylelint-disable no-descending-specificity */
.hero-bodega {
  padding: 40px 0;
  position: relative;
  z-index: 32;

  @include from($tablet) {
    padding: 50px 0 25px;
  }

  @include from($widescreen) {
    padding: 80px 0 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 78%;
    background-color: #f7e8d6;
    z-index: -1;

    @include from($tablet) {
      height: calc(100% - 90px);
    }

    @include from($fullhd) {
      height: calc(100% - 120px);
    }
  }

  &::after {
    @include from($desktop) {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 306px;
      height: 90px;
      background: #fff;
      z-index: 3;
    }

    @include from($fullhd) {
      height: 120px;
    }
  }

  .container {
    max-width: 1700px !important;
    padding: 0 0.75rem;
  }

  .columns-boxes {
    flex-direction: column;
    position: relative;

    @include from($tablet) {
      flex-direction: row;
      align-items: flex-end;
    }

    @include from($widescreen) {
      align-items: flex-start;
    }

    .column-box-left {
      order: 2;

      @include from($tablet) {
        order: 1;
      }

      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .column-box-right {
      order: 1;
      // position: relative;

      @include from($tablet) {
        order: 2;
      }

      .inner {
        padding: 0 20px;

        @include from($tablet) {
          padding: 15px 0 40px 15px;
          max-width: 640px;
          width: 100%;
        }

        @include from($widescreen) {
          padding: 25px 20px 60px 35px;
        }

        @include from($fullhd) {
          padding: 25px 20px 80px 50px;
        }

        .text-eyebrow {
          margin: 0 0 20px;
        }

        .h1 {
          @include from($tablet) {
            margin: 0 0 20px;
          }

          @include from($fullhd) {
            margin: 0 0 30px;
          }
        }

        .h5 {
          margin: 0 0 40px;

          @include from($tablet) {
            margin: 0 0 70px;
          }

          @include from($widescreen) {
            margin: 0 0 90px;
          }

          @include from($fullhd) {
            margin: 0 0 115px;
          }
        }

        .social-icons-rounded {
          li {
            margin-right: 10px;

            @include from($tablet) {
              margin-right: 18px;
            }
          }
        }
      }

      .hero-bodega-image {
        display: none;

        @include from($desktop) {
          display: block;
          position: absolute;
          top: -40px;
          right: 20px;
          width: auto;
          height: calc(100% - 37px);
          z-index: -1;
        }

        @include from($widescreen) {
          // width: 240px;
          height: calc(100% + 10px);
          top: -72px;
        }

        @include from($fullhd) {
          // width: 306px;
          height: calc(100% - 20px);
        }

        svg {
          @include from($desktop) {
            width: 100%;
            height: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

.bodega-contact {
  padding-top: 0;
  padding-bottom: 25px;

  @include from($tablet) {
    padding-top: 0;
    padding-bottom: 35px;
  }

  @include from($fullhd) {
    padding-top: 0;
    padding-bottom: 40px;
  }

  h2 {
    margin: 0 0 10px;
  }

  .address-inner-left {
    @include from($tablet) {
      padding-right: 15px;
    }
  }

  address {
    display: block;
    font-style: normal;
    margin-bottom: 50px;
    font-size: 15px;
    line-height: 1.6em;
    font-weight: 400;

    @include from($tablet) {
      font-size: 16px;
    }

    @include from($fullhd) {
      font-size: 19px;
    }

    .address-group {
      margin-bottom: 20px;
    }

    .g-map {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 1.6em;
      font-weight: 500;
      color: $main-color;
      text-decoration: underline;
      margin-bottom: 20px;

      &:hover {
        text-decoration: none;
      }

      @include from($tablet) {
        margin-bottom: 0;
      }

      @include from($fullhd) {
        font-size: 15px;
      }

      svg {
        display: block;
        margin-right: 10px;
      }
    }
  }

  ul {
    li {
      display: block;
      margin-bottom: 16px;
    }

    li a {
      display: flex;
      align-items: center;
      width: 100%;
      color: $main-color;
      font-size: 13px;
      line-height: 1.6em;
      letter-spacing: 0.075em;

      @include from($fullhd) {
        font-size: 14px;
      }

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }

      svg {
        display: block;
        margin-right: 10px;
      }
    }
  }
}

.bodega-brands {
  padding-top: 0;
  padding-bottom: 25px;

  @include from($tablet) {
    padding-top: 0;
    padding-bottom: 35px;
  }

  @include from($fullhd) {
    padding-top: 0;
    padding-bottom: 40px;
  }

  .h5 {
    margin: 0 0 25px;
  }

  .box-items {
    .box-item {
      width: 50%;
      padding: 0 20px 25px 0;

      @include from($tablet) {
        width: calc(100% / 3);
      }

      .box-item-inner {
        display: flex;
        width: 100%;
      }

      .box-item-image {
        width: 37px;
        flex-shrink: 0;
      }

      .box-item-content {
        width: calc(100% - 37px);
        padding-left: 15px;

        .text-eyebrow {
          margin: 0 0 5px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .bodega-brands-back-button {
    margin-top: 30px;

    @include from($tablet) {
      margin-top: 35px;
    }

    @include from($widescreen) {
      margin-top: 45px;
    }

    a {
      font-size: 16px;
      line-height: 1.3em;

      @include from($tablet) {
        font-size: 22px;
      }

      @include from($fullhd) {
        font-size: 26px;
      }

      svg {
        display: block;
        margin-right: 10px;
      }

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }
    }
  }
}
