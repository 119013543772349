/* stylelint-disable no-descending-specificity */
.block-three_picture_blocks {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;

    /* .container.is-fluid {
      padding-left: 0;
      padding-right: 0;
    } */

    .box-item {
      min-height: 510px;
      display: flex;
      align-items: flex-end;

      @include from($desktop) {
        min-height: 610px;
      }

      @include from($widescreen) {
        min-height: 710px;
      }

      img.main-img {
        object-fit: cover;
        transition: all 0.2s linear;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .bgr-overlay {
        position: absolute;
        inset: 0;
        background: linear-gradient(rgba(49, 44, 40, 0) 0%, $main-color 100%);
        opacity: 0.48;
        border-radius: 10px;
        transition: all 0.2s linear;
        z-index: 2;
      }

      .inner {
        padding: 30px 25px 30px 20px;
        z-index: 2;

        @include from($tablet) {
          padding: 35px 35px 35px 25px;
        }

        @include from($desktop) {
          padding: 35px 60px 45px 35px;
        }
      }

      .h2 {
        margin: 0 0 20px;
      }

      .hidden-text {
        padding-bottom: 20px;
        transition: opacity 1s ease-out;
        opacity: 0;
        height: 0;
        overflow: hidden;

        p {
          color: $white-color;
        }
      }

      .box-item-link {
        display: inline-block;
        font-family: $font-title;
        font-size: 16px;
        line-height: 1em;
        font-weight: 500;
        color: $white-color !important;

        @include from($tablet) {
          font-size: 22px;
        }

        @include from($fullhd) {
          font-size: 26px;
        }

        svg {
          display: inline-block;
          width: 18px !important;
          height: 10px !important;
          margin-right: 10px;

          @include from($tablet) {
            width: 26px !important;
            height: 18px !important;
            margin-right: 20px;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover .hidden-text {
        opacity: 1;
        height: auto;
      }

      &:hover .bgr-overlay {
        opacity: 1;
      }
    }
  }
}
