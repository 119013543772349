.block-text {
  .section {
    padding-top: 30px;
    padding-bottom: 30px;

    @include from($widescreen) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}
