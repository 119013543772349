/* stylelint-disable no-descending-specificity */
.author-box {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 10px 0;
  margin: 30px 0;

  .author-wrap {
    .author-image {
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .author-info {
      p {
        font-size: 16px;
        color: #898989;
        margin: 0;
      }
    }
  }

  .author-social {
    width: 100%;
    margin-top: 10px;

    @include from($tablet) {
      width: auto;
      margin-top: 0;
    }

    p {
      font-size: 16px;
      color: #898989;
      margin: 0;
    }
  }
}
