.sherry-spot-section {
  padding-top: 0;
  padding-bottom: 40px;

  @include from($tablet) {
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-bottom: 110px;
  }

  .sherry-spot-content {
    img {
      display: block;
    }

    .sherry-spot-info {
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      margin: 20px auto 50px;
      padding: 25px 0 1px;

      ul {
        li {
          position: relative;
          display: block;
          margin-bottom: 24px;
          font-size: 13px;
          line-height: 1.6em;
          letter-spacing: 0.075em;
          padding-left: 40px;

          @include from($widescreen) {
            font-size: 14px;
          }

          svg {
            display: block;
            position: absolute;
            top: -2px;
            left: 0;
            width: 19px !important;
          }

          .g-map {
            display: inline-block;
            font-size: 15px;
            line-height: 1.6em;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .social-share {
        padding-bottom: 25px;
      }
    }

    .sherry-spot-map {
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      margin: 50px auto;
      padding: 25px 0;
    }

    .sherry-spot-desc {
      padding-bottom: 50px;

      img {
        border-radius: 10px;
      }
    }
  }
}

.where-to-buy .button.is-small {
  padding: 20px 10px !important;
  display: block;
}

.sherry-spot-section .sherry-spot-content .where-to-buy img {
  border-radius: 5px 5px 0 0;
}

.sherry-spot-section .sherry-spot-content .where-to-buy .box-item {
  border-radius: 5px;
}
