/* stylelint-disable no-descending-specificity */
.showcookies {
  display: block !important;
}

.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 1.5rem;
  z-index: 99;

  @include from($tablet) {
    padding: 20px 1.5rem;
  }

  .text-base,
  .text-cookie {
    color: #fbf8f5;
    margin: 0 0 5px;
  }

  .text-cookie {
    font-size: 10px;
  }

  ul {
    @include from($tablet) {
      justify-content: center;
    }

    li {
      margin-right: 40px;
      text-align: center;

      @include from($tablet) {
        margin: 0 0 0 20px;
      }

      @include from($desktop) {
        margin: 0 0 0 30px;
      }

      @include from($widescreen) {
        margin: 0 0 0 40px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    li a {
      &.button {
        background-color: #fbf8f5 !important;
        color: $main-color !important;
        padding-left: 32px !important;
        padding-right: 32px !important;
        font-size: 14px !important;

        @include from($desktop) {
          padding-left: 48px !important;
          padding-right: 48px !important;
        }

        &:hover {
          background-color: $white-color !important;
        }
      }

      &:not(.button) {
        font-size: 12px !important;
        line-height: 1em;
        font-weight: 700;
        letter-spacing: 0.075em;
        color: #fbf8f5;

        @include from($tablet) {
          font-size: 14px;
        }

        @include from($widescreen) {
          font-size: 14px !important;
        }

        &:hover {
          // text-decoration: underline;
          color: $hover-color;
        }
      }
    }
  }
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
