/* stylelint-disable no-descending-specificity */
.grid-bottles-section {
  padding-top: 45px;
  padding-bottom: 90px;

  .container {
    max-width: 1215px !important;
  }

  .grid-bottles-slider-wrap {
    .tns-nav {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -45px;
      margin: 0 auto;
      text-align: center;

      @include from($desktop) {
        display: none;
      }

      button {
        width: 12px;
        height: 12px;
        border: 0 none;
        border-radius: 50%;
        background-color: $accent-color;
        padding: 0;
        display: inline-block;
        margin: 0 5px;

        &.tns-nav-active {
          background-color: $sixth-color;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .grid-bottles-slider-controls {
      display: none;

      @include from($desktop) {
        display: block;
      }

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 0 none;
        background-color: $accent-color;
        cursor: pointer;

        svg {
          display: block;
          height: 18px !important;
        }

        &:focus {
          outline: 0;
        }

        &:hover {
          background-color: rgba(252, 248, 244, 0.83);
        }

        &[disabled] {
          background-color: rgba(252, 248, 244, 0.43);

          svg {
            opacity: 0.25;
          }
        }

        &.grid-bottles-slider-prev {
          left: -60px;
        }

        &.grid-bottles-slider-next {
          right: -60px;
        }
      }
    }
  }

  .grid-bottles-slider {
    display: none;

    &.tns-slider {
      display: flex;
    }

    .slideshow {
      .slideshow-item {
        display: flex;
        height: 100%;
        padding: 46px 20px 20px;
        transition: all 0.2s linear;
      }

      .slideshow-item-wrap {
        width: 100%;
        height: 100%;
      }

      .slideshow-text {
        .text-eyebrow {
          margin: 0 0 10px;
        }

        .h4 {
          margin: 0 0 20px;
        }
      }

      .slideshow-image {
        max-height: 340px;
        display: flex;
        // align-items: center;
        align-items: flex-end;
        justify-content: center;

        @include from($tablet) {
          max-height: 360px;
        }

        @include from($desktop) {
          max-height: 440px;
        }

        @include from($widescreen) {
          max-height: 512px;
        }

        img {
          display: block;
          width: auto;
          height: auto;
          max-height: 300px;

          @include from($tablet) {
            max-height: 320px;
          }

          @include from($desktop) {
            max-height: 400px;
          }

          @include from($widescreen) {
            max-height: 500px;
          }
        }
      }
    }
  }
}
