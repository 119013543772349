/* stylelint-disable no-descending-specificity */
.download-cards-section {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 20px;

  @include from($tablet) {
    padding-top: 55px;
    padding-bottom: 55px;
    margin-bottom: 30px;
  }

  @include from($widescreen) {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 40px;
  }

  h2 {
    margin: 0 0 22px;

    @include from($tablet) {
      margin: 0 0 37px;
    }

    @include from($widescreen) {
      margin: 0 0 50px;
    }
  }

  .grid-item {
    border: 2px solid $accent-color;
    border-radius: 10px;

    .grid-item-img {
      img {
        display: block;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
      }
    }

    .grid-item-content {
      padding: 35px 35px 40px 35px;
      position: relative;

      .grid-item-content-url {
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: block;
        width: 49px;
        height: 49px;
        transition: all 0.2s linear;

        @include from($widescreen) {
          right: 25px;
        }

        svg {
          height: 23px !important;
        }

        &:hover {
          background-color: $main-color !important;
        }
      }
    }
  }
}
