.social-icons-rounded {
  li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid $sixth-color;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.2s linear;

    @include from($tablet) {
      width: 47px;
      height: 47px;
    }

    svg {
      display: block;
      height: 12px !important;

      @include from($tablet) {
        height: 17px !important;
      }

      path {
        fill: $main-color;
        transition: all 0.2s linear;
      }
    }

    &:hover {
      border: 1px solid $main-color;
      background-color: $main-color;
    }

    &:hover svg path {
      fill: $white-color;
    }
  }
}
