/* stylelint-disable no-descending-specificity */
.entry-section {
  padding-top: 55px;
  padding-bottom: 55px;

  @include from($tablet) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include from($widescreen) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .entry-button {
    margin: 30px 0 40px;
  }

  .entry p {
    @extend .text-xl;
  }
}

.bodega-informations {
  padding-top: 10px;
  padding-bottom: 55px;
  background-color: rgba(203, 178, 147, 0.09);

  @include from($tablet) {
    padding-top: 10px;
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-top: 10px;
    padding-bottom: 90px;
  }

  .box-item {
    border-top: 1px solid #d6d6d6;
    width: 100%;
    padding: 25px 20px;

    @include from($tablet) {
      padding: 35px 20px;
    }

    @include from($widescreen) {
      padding: 50px 0;
    }

    .box-item-image {
      width: 120px;
      margin-bottom: 20px;

      @include from($tablet) {
        margin-bottom: 0;
        padding-top: 13px;
        flex-shrink: 0;
      }

      @include from($widescreen) {
        width: 165px;
      }
    }

    .box-item-content {
      width: 100%;

      @include from($tablet) {
        width: calc(100% - 120px);
        padding-left: 20px;
      }

      @include from($widescreen) {
        width: calc(100% - 165px);
        padding-left: 50px;
      }
    }

    .box-item-header {
      .h4 {
        margin: 0 0 19px;
      }
    }

    ul {
      li {
        display: block;
        width: 100%;
        margin-bottom: 15px;

        @include from($tablet) {
          width: 50%;
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;

          @include from($tablet) {
            width: 50%;
            margin-bottom: 0;
          }
        }

        address {
          font-style: normal;
          font-size: 15px;
          line-height: 1.6em;
          font-weight: 400;

          @include from($tablet) {
            font-size: 16px;
          }

          @include from($widescreen) {
            font-size: 19px;
          }

          a {
            color: $main-color;

            &:hover {
              // text-decoration: underline;
              color: $hover-color;
            }
          }
        }

        .box-item-links a {
          display: inline-block;
          font-size: 13px;
          line-height: 1.6em;
          letter-spacing: 0.075em;
          color: $main-color;
          text-transform: none;
          margin-bottom: 10px;

          @include from($widescreen) {
            font-size: 14px;
          }

          &:hover {
            // text-decoration: underline;
            color: $hover-color;
          }
        }
      }
    }
  }
}
