/* stylelint-disable no-descending-specificity */
.event-page-section {
  padding-top: 40px;
  padding-bottom: 90px;

  @include from($tablet) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @include from($widescreen) {
    padding-top: 50px;
    padding-bottom: 140px;
  }

  .event-page-headline {
    margin-bottom: 40px;

    @include from($tablet) {
      margin-bottom: 60px;
    }

    .text-eyebrow {
      &.secondary {
        font-size: 12px;
        color: #898989;
      }
    }
  }

  .event-content {
    .event-content-img {
      img {
        display: block;
        border-radius: 10px;
      }
    }
  }

  .event-content-informations {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    margin: 20px auto;
    padding: 25px 0;
    position: relative;

    .event-content-informations-list {
      li {
        position: relative;
        display: block;
        margin-bottom: 24px;
        font-size: 13px;
        line-height: 1.4em;
        letter-spacing: 0.075em;
        padding-left: 40px;

        @include from($widescreen) {
          font-size: 14px;
        }

        svg {
          display: block;
          position: absolute;
          top: -2px;
          left: 0;
          width: 19px !important;
        }
      }
    }
  }

  .event-page-description {
    margin: 50px 0;
  }

  .event-page-calendar {
    .h6 {
      margin: 0 0 25px;
    }

    ul {
      border-top: 1px solid #e3e3e3;

      li {
        border-bottom: 1px solid #e3e3e3;
        font-size: 15px;
        line-height: 1.6em;
        padding: 20px 0;

        @include from($tablet) {
          font-size: 16px;
        }

        @include from($widescreen) {
          padding: 25px 0;
        }

        @include from($fullhd) {
          font-size: 19px;
        }

        a.add-to-calendar {
          font-size: 14px;
          line-height: 1.6em;
          text-decoration: underline;

          @include from($tablet) {
            font-size: 15px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .event-page-contact-button {
    padding: 35px 0 45px;
    border-bottom: 1px solid #e3e3e3;

    @include from($tablet) {
      padding: 45px 0 55px;
    }

    @include from($widescreen) {
      padding: 75px 0 90px;
    }

    .button {
      display: block;
      max-width: 430px;
      width: 100%;
    }
  }

  .event-page-map {
    margin-top: 30px;

    @include from($tablet) {
      margin-top: 40px;
    }

    img {
      display: block;
      border-radius: 10px;
    }
  }
}
