/* stylelint-disable no-descending-specificity */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  padding: 17px 0;
  transition: all 0.2s linear;

  @include from($desktop) {
    padding: 0;
  }

  .container {
    position: relative;
    max-width: 100% !important;
  }

  .btn-menu {
    position: absolute;
    top: 13px;
    width: 30px;
    height: 23px;
    right: 1.5rem;
    display: block;
    z-index: 99;
    padding: 0;
    background: none;
    border: 0 none;

    &:focus {
      outline: 0;
    }

    @include from($desktop) {
      display: none;
    }
  }

  .btn-menu::before,
  .btn-menu::after,
  .btn-menu span {
    background: $white-color;
    position: absolute;
    width: 100%;
    top: 9px;
    right: 0;
    height: 2px;
    transition: all 0.2s linear;
  }

  .btn-menu::before,
  .btn-menu::after {
    content: '';
    top: 0;
  }

  .btn-menu span {
    width: 100%;
  }

  .btn-menu::after {
    width: 60%;
    top: 18px;
  }

  .btn-menu.active span {
    opacity: 0;
  }

  .btn-menu.active::before,
  .btn-menu.active::after {
    transform: rotate(45deg);
    top: 10px;
    left: 0;
    right: 0;
    background: $white-color;
  }

  .btn-menu.active::after {
    transform: rotate(-45deg);
    width: 100%;
  }

  .header-navigation {
    position: fixed;
    top: 81px;
    left: 0;
    width: 100%;
    height: calc(100vh - 81px);
    z-index: -99;
    background-color: $main-color;
    padding: 30px 1.5rem;

    @media only screen and (max-width: 1023px) {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s linear;
    }

    @include from($desktop) {
      position: initial;
      top: initial;
      left: initial;
      width: initial;
      height: initial;
      background-color: none;
      padding: 0;
      display: block !important;
    }

    .header-navigation-wrap {
      display: flex;
      flex-direction: column;

      @include from($desktop) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    &.active {
      @media only screen and (max-width: 1023px) {
        z-index: 99;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .header-logo-primary,
  .header-logo-secondary {
    a {
      display: block;

      svg {
        display: block;
      }
    }
  }

  .header-logo-primary {
    margin-left: 1.5rem;

    @include from($desktop) {
      margin-left: 0;
      position: absolute;
      top: 14px;
      left: 1.5rem;
    }

    @include from($widescreen) {
      left: 50px;
    }
  }

  .header-search {
    position: absolute;
    right: 72px;
    top: 10px;
    z-index: 11;

    @include from($desktop) {
      top: 26px;
      right: initial;
      left: 70px;
    }

    @include from($widescreen) {
      top: 28px;
      left: initial;
      right: 170px;
    }

    a {
      display: block;
      width: 27px;
      height: 27px;

      svg {
        display: block;
        width: 27px !important;
        height: 27px !important;
      }
    }
  }

  .header-logo-secondary {
    position: fixed;
    bottom: 30px;
    right: 1.5rem;
    z-index: 102;

    @include from($desktop) {
      position: absolute;
      bottom: initial;
      top: 15px;
    }

    @include from($widescreen) {
      right: 50px;
    }

    img {
      display: block;
    }

    .header-logo-secondary-white {
      display: block;
    }

    .header-logo-secondary-colored {
      display: none;
    }
  }

  .navigation-menu {
    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 81px;
      left: 0;
      width: 100%;
      height: calc(100vh - 81px);
      z-index: 98;
    }

    @include from($desktop) {
      position: relative;
    }

    @include from($desktop) {
      position: initial;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > li {
      &:first-child > a {
        @include from($tablet) {
          border-top: 1px solid rgba(229, 217, 198, 0.07);
        }

        @include from($desktop) {
          border-top: 0 none;
        }
      }

      &.has-menu-secondary > a {
        @media only screen and (max-width: 1023px) {
          position: relative;
        }

        @media screen and (min-width: 769px) and (max-width: 1023px) {
          padding-right: 40px;
        }

        .menu-second-toggle {
          position: absolute;
          top: 0;
          right: 0;
          width: 70px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 33;

          @include from($tablet) {
            width: 30px;
          }

          &::before {
            content: '';
            position: absolute;
            width: 21px;
            height: 15px;
            background-image: url(../images/icon-menu-arrow.svg);
            background-size: 21px 15px;
            background-repeat: no-repeat;
          }

          @include from($desktop) {
            display: none;
          }
        }
      }
    }

    > li > a {
      display: block;
      font-size: 14px;
      line-height: 1.3em;
      font-weight: 600;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $sixth-color;
      padding: 15px 75px 15px 1.5rem;
      transition: all 0.2s linear;
      width: 100%;
      border-bottom: 1px solid rgba(229, 217, 198, 0.07);

      @media only screen and (max-width: 768px) {
        position: relative;
      }

      @include from($tablet) {
        width: 32%;
        font-size: 14px;
        padding: 15px;
      }

      @include from($desktop) {
        font-size: 12px;
        letter-spacing: 0.075em;
        padding: 30px 7px;
        color: #f2ece4;
        width: auto;
        border: 0 none;
      }

      @media only screen and (min-width: 1320px) {
        padding: 30px 13px;
      }

      @media only screen and (min-width: 1360px) {
        font-size: 13px;
      }

      @media only screen and (min-width: 1500px) {
        padding: 30px 24px;
      }

      &:hover {
        background-color: $seventh-color;
      }
    }
  }

  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background-color: $main-color;
    display: none;
    height: 80px;

    @include from($tablet) {
      display: none !important;
    }

    &.active {
      display: block;
    }

    .mobile-header-wrap {
      height: 80px;
      padding: 0 1.5rem;
    }

    p {
      letter-spacing: 0.1em;
    }

    button {
      display: inline-block;
      border: 0 none;
      padding: 0;
      background: transparent;

      &:focus {
        outline: 0;
      }
    }

    .mobile-header-close {
      position: relative;
      width: 30px;
      height: 23px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: $white-color;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .navigation-menu-dropdown {
    width: 100%;
    background-color: $main-color;

    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 81px;
      left: 100%;
      width: 100%;
      height: calc(100vh - 81px);
      z-index: -99;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
    }

    @include from($tablet) {
      display: none;
      position: absolute;
      top: 30px;
      left: 35%;
      width: calc(65% - 1.5rem);
      padding-bottom: 35px;
    }

    @include from($desktop) {
      left: 0;
      top: 100%;
      width: 100%;
      height: auto;
      padding: 45px 45px 45px 75px;
      background-color: $seventh-color;
    }

    &.active {
      @media only screen and (max-width: 768px) {
        left: 0;
        z-index: 99;
        opacity: 1;
        visibility: visible;
      }

      @include from($tablet) {
        display: block;
      }
    }

    .navigation-menu-dropdown-inner {
      @media only screen and (max-width: 768px) {
        height: calc(100% - 110px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      @include from($tablet) {
        display: flex;
        justify-content: space-between;
      }

      @include from($desktop) {
        position: relative;
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;
      }

      .menu-secondary {
        position: relative;

        @include from($tablet) {
          width: calc(100% - 260px);
        }

        @include from($desktop) {
          width: 400px;
        }

        > li {
          display: block;
          border-bottom: 1px solid rgba(229, 217, 198, 0.07);

          @include from($desktop) {
            border-bottom: 0 none !important;
          }

          &:first-child > a {
            @include from($tablet) {
              border-top: 1px solid rgba(229, 217, 198, 0.07);
            }

            @include from($desktop) {
              border-top: 0 none;
            }
          }

          &:last-child {
            border-bottom: 0 none;
          }
        }

        > li > a {
          display: block;
          font-size: 14px;
          line-height: 1.3em;
          font-weight: 600;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $sixth-color;
          padding: 15px 65px 15px 1.5rem;
          position: relative;

          @include from($tablet) {
            padding: 15px 45px 15px 15px;
            font-size: 13px;
          }

          @include from($desktop) {
            padding: 15px;
            letter-spacing: 0.075em;
          }

          .menu-third-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            width: 60px;
            height: 100%;

            @include from($tablet) {
              width: 40px;
            }

            @include from($desktop) {
              display: none;
            }

            &::before {
              content: '+';
              font-size: 30px;
              line-height: 1em;
              color: $sixth-color;
              font-weight: 600;
            }

            &.active::before {
              content: '-';
            }
          }

          &:hover {
            @include from($desktop) {
              // text-decoration: underline;
              background-color: $main-color;
            }
          }
        }

        .menu-third {
          display: none;
          padding: 15px 0;
          border-top: 1px solid rgba(229, 217, 198, 0.07);

          @include from($desktop) {
            position: absolute;
            top: 0;
            left: 400px;
            width: 400px;
            height: 100%;
            z-index: 77;
            border-top: 0 none;
            background: $seventh-color;
            padding-left: 15px;
          }

          &.active {
            display: block;
          }

          li {
            display: block;

            @include from($desktop) {
              margin-bottom: 20px;
            }

            &:last-child {
              @include from($desktop) {
                margin-bottom: 0;
              }
            }
          }

          li a {
            display: block;
            font-size: 15px;
            line-height: 1.3em;
            font-weight: 400;
            color: $white-color;
            padding: 10px 1.5rem;
            position: relative;
            z-index: 32;

            @include from($tablet) {
              padding: 10px 13px;
            }

            @include from($desktop) {
              display: inline-block;
              font-size: 13px;
              padding: 0;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .navigation-menu-img {
      display: none;

      @include from($tablet) {
        display: block;
        max-width: 220px;
        width: 100%;
      }

      @include from($desktop) {
        max-width: 420px;
      }

      img {
        width: 100%;
        margin-bottom: 15px;
      }

      p {
        font-size: 13px;
        line-height: 1.4em;
        color: $white-color;
        margin: 0;

        @include from($desktop) {
          font-size: 17px;
        }
      }
    }
  }

  .header-lang {
    position: relative;

    span {
      display: inline-block;
      font-size: 10px;
      line-height: 1.3em;
      font-weight: 600;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      color: $white-color;
      position: fixed;
      bottom: 30px;
      left: 1.5rem;
      z-index: 102;
      cursor: pointer;

      @include from($desktop) {
        position: initial;
        bottom: initial;
        left: initial;
        font-size: 13px;
        color: $sixth-color;
        padding: 29px 7px;
      }

      @media only screen and (min-width: 1320px) {
        padding: 29px 13px;
      }

      @media only screen and (min-width: 1500px) {
        padding: 29px 25px;
      }
    }

    .header-lang-menu {
      position: fixed;
      bottom: 46px;
      left: 1.5rem;
      display: none;

      @include from($desktop) {
        position: absolute;
        bottom: initial;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        background-color: $seventh-color;
        // padding: 10px 0;
      }

      &.active {
        display: block;
      }

      ul {
        display: flex;
        flex-direction: column-reverse;

        @include from($desktop) {
          flex-direction: column;
        }

        li {
          display: block;
          text-align: left;

          @include from($desktop) {
            text-align: center;
          }

          &:first-child a {
            padding: 10px 0 15px;

            @include from($desktop) {
              padding: 10px 0;
            }
          }
        }

        li a {
          font-size: 10px;
          display: block;
          letter-spacing: 0.075em;
          text-transform: uppercase;
          color: #f2ece4;
          padding: 10px 0;

          @include from($desktop) {
            font-size: 13px;
          }

          &:hover {
            background-color: $main-color;
          }
        }
      }
    }
  }
}

.no-touch .header-lang span:hover {
  @include from($desktop) {
    background-color: $seventh-color;
  }
}

.no-touch .header-lang span:hover .header-lang-menu {
  display: block;
}

.no-touch .navigation-menu-dropdown li.has-menu-third:hover > a {
  @include from($desktop) {
    // text-decoration: underline;
    background-color: $main-color;
  }
}

.no-touch .navigation-menu-dropdown li.has-menu-third:hover .menu-third {
  @include from($desktop) {
    display: block;
  }
}

.no-touch .navigation-menu li.has-menu-secondary:hover > a {
  @include from($desktop) {
    background-color: $seventh-color;
  }
}

.no-touch .navigation-menu li.has-menu-secondary:hover .navigation-menu-dropdown {
  display: block;
}

.touch .navigation-menu li .navigation-menu-dropdown.tablet-active {
  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    display: block;
  }
}

.no-touch .navigation-menu li .navigation-menu-dropdown .menu-secondary li:first-child .menu-third.desktop-active {
  @include from($desktop) {
    display: block;
  }
}

.top-space {
  padding-top: 77px;
}

.home-page {
  .header {
    background-color: transparent !important;

    .header-navigation {
      background-color: transparent;
    }

    &.sticky {
      background-color: $main-color !important;
    }

    &.sticky .header-navigation {
      background-color: $main-color;
    }

    &:hover {
      background-color: $main-color !important;
    }
  }

  .top-space {
    padding-top: 0;
  }
}
