.divider-section {
  padding-top: 20px;
  padding-bottom: 20px;

  @include from($tablet) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include from($desktop) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  @include from($widescreen) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  /* .container.is-fluid {
    padding-left: 0;
    padding-right: 0;
  } */

  .divider {
    border-top: 1px solid #d6d6d6;
  }
}
