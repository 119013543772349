.footer {
  padding: 0;
  background: none;

  img {
    display: block;
    margin: 0 auto;
  }

  .container {
    max-width: 1680px !important;
    width: calc(100% - 3rem);

    @include from($desktop) {
      width: calc(100% - 6rem);
    }
  }

  .footer-top {
    padding: 45px 0 19px;

    @include from($widescreen) {
      padding: 79px 0 19px;
    }

    .footer-top-box {
      margin-bottom: 45px;

      @include from($widescreen) {
        margin-bottom: 30px;
      }

      .footer-logo-mt {
        display: block;
        margin: 0 auto 25px;

        @include from($widescreen) {
          display: none;
        }
      }

      h5,
      h6,
      p {
        color: $sixth-color;
      }

      h5,
      p {
        font-size: 10px;
        line-height: 1.3em;
        font-weight: 300;
        letter-spacing: 0.185em;
        margin: 0 0 24px;

        @include from($tablet) {
          font-size: 14px;
        }

        @include from($widescreen) {
          font-size: 16px;
        }
      }

      h6 {
        font-size: 37px;
        line-height: 1em;
        font-weight: 400;
        color: $white-color;
        margin: 0 0 20px;

        @media only screen and (max-width: 375px) {
          font-size: 30px;
        }

        @include from($tablet) {
          font-size: 59px;
        }

        @include from($widescreen) {
          font-size: 69px;
        }
      }

      p {
        margin: 0 0 50px;
      }

      ul {
        li {
          margin: 0 16px;
        }

        li a {
          display: block;

          svg {
            display: block;
          }

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    .footer-menu {
      margin-bottom: 57px;

      .footer-menu-item {
        width: 100%;
        margin-bottom: 45px;

        @include from($tablet) {
          float: left;
          width: calc(100% / 3);
          padding-right: 15px;
          margin-bottom: 0;
        }

        @include from($widescreen) {
          width: calc(100% / 6);
        }

        &:nth-child(n+2) {
          @include from($widescreen) {
            padding-top: 34px;
          }
        }

        &:first-child,
        &:last-child {
          margin-bottom: 0;
        }

        &:nth-last-child(2),
        &:last-child {
          @include from($tablet) {
            margin-top: -70px;
          }

          @include from($widescreen) {
            margin-top: 0;
          }
        }

        .footer-logo-desktop {
          display: none;

          @include from($widescreen) {
            display: block;
          }
        }

        h6 {
          font-size: 14px;
          line-height: 1.35em;
          font-weight: 300;
          letter-spacing: 0.185em;
          color: $sixth-color;
          margin: 0 0 17px;
        }

        ul {
          li {
            display: block;
            margin-bottom: 9px;
          }

          li a {
            display: inline-block;
            font-size: 15px;
            line-height: 1.35em;
            font-weight: 400;
            color: $white-color;

            @include from($tablet) {
              font-size: 13px;
            }

            @include from($desktop) {
              font-size: 13px;
            }

            @include from($widescreen) {
              font-size: 14px;
            }

            &:hover {
              // text-decoration: underline;
              color: $hover-color;
            }
          }
        }
      }
    }

    .footer-caption {
      p {
        font-size: 11px;
        line-height: 1.423em;
        font-weight: 300;
        letter-spacing: 0.02em;
        color: $sixth-color;
        margin: 0 0 15px;

        @include from($tablet) {
          font-size: 14px;
        }
      }
    }
  }

  .footer-bottom {
    padding: 6px 0;

    @include from($tablet) {
      padding: 12px 0;
    }

    .container {
      max-width: 480px !important;

      @include from($tablet) {
        max-width: 100% !important;
      }

      @include from($widescreen) {
        max-width: 1080px !important;
      }
    }

    .footer-logos {
      flex-wrap: wrap;

      @include from($tablet) {
        flex-wrap: nowrap;
      }

      > div {
        padding: 12px;
        width: calc(100% / 3);

        @include from($tablet) {
          padding: 0 12px;
          width: auto;
        }

        @include from($widescreen) {
          padding: 0 24px;
        }
      }
    }
  }
}
