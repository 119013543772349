.filter-card-section {
  padding-top: 75px;
  padding-bottom: 0;

  @include from($widescreen) {
    padding-top: 100px;
  }

  .container {
    max-width: 1630px !important;
  }

  .h2 {
    margin: 0 0 26px;

    @include from($tablet) {
      margin: 0 0 30px;
    }

    @include from($widescreen) {
      margin: 0 0 40px;
    }
  }

  .boxes {
    .box-item {
      border-radius: 10px;
      background-color: #fbf8f5;
      width: 100%;

      @include from($tablet) {
        width: 33%;
      }

      @include from($widescreen) {
        width: 25%;
      }

      .box-item-image {
        img {
          display: block;
          width: 100%;
          margin: 0 auto;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .box-item-content {
        padding: 24px 20px;

        @include from($tablet) {
          padding: 32px 28px;
        }

        @include from($widescreen) {
          padding: 48px 36px;
        }

        .text-eyebrow {
          margin: 0 0 15px;
        }

        .h4 {
          line-height: 1em;
          margin: 0;

          a {
            color: $main-color;
          }
        }
      }
    }
  }
}
