.has-background-primary {
  background-color: $main-color !important;
}

.has-background-secondary {
  background-color: $second-color !important;
}

.has-background-accent {
  background-color: $accent-color !important;
}

.is-primary {
  color: $main-color !important;
}

.is-secondary {
  color: $second-color !important;
}
