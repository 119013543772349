/* stylelint-disable no-descending-specificity */
.quicklinks-section {
  padding-top: 40px;
  padding-bottom: 45px;
  position: relative;
  z-index: 32;

  @include from($tablet) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include from($widescreen) {
    padding-top: 80px;
    padding-bottom: 45px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 78%;
    background-color: #f7e8d6;
    z-index: -1;

    @include from($tablet) {
      height: calc(100% - 65px);
    }

    @include from($fullhd) {
      height: calc(100% - 120px);
    }
  }

  .container {
    max-width: 1720px !important;
    // padding: 0 20px;
  }

  .columns-boxes {
    flex-direction: column;

    @include from($tablet) {
      flex-direction: row;
      align-items: flex-end;
    }

    @include from($desktop) {
      min-height: 480px;
    }

    @include from($widescreen) {
      align-items: flex-start;
      min-height: 540px;
    }

    @include from($fullhd) {
      align-items: flex-start;
      min-height: 600px;
    }

    .column-box-left {
      order: 2;

      @include from($tablet) {
        order: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }

      img {
        width: 100%;
        border-radius: 10px;

        @include from($tablet) {
          position: absolute;
          top: 0;
          left: 0.75rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .column-box-right {
      order: 1;

      @include from($tablet) {
        order: 2;
        margin-left: auto;
      }

      .inner {
        // padding: 0 20px;

        @include from($tablet) {
          padding: 15px 0 50px 30px;
        }

        @include from($fullhd) {
          padding: 25px 20px 100px 60px;
        }

        .text-eyebrow {
          margin: 0 0 20px;
        }

        .h1 {
          @include from($tablet) {
            margin: 0 0 46px;
          }

          @include from($fullhd) {
            margin: 0 0 91px;
          }
        }
      }
    }
  }

  &.orange {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #d37700;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-orange.svg);
        }

        &:hover {
          color: #d37700;
        }
      }
    }

    &::before {
      background-color: #f7e8d6;
    }
  }

  &.purple {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #a25570;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-purple.svg);
        }

        &:hover {
          color: #90435e;
        }
      }
    }

    &::before {
      background-color: #efdbe2;
    }
  }

  &.yellow {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #d6aa38;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-yellow.svg);
        }

        &:hover {
          color: #d6aa38;
        }
      }
    }

    &::before {
      background-color: #faf2da;
    }
  }

  &.green {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #779345;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-green.svg);
        }

        &:hover {
          color: #7f924c;
        }
      }
    }

    &::before {
      background-color: #e8efd3;
    }
  }

  &.blue {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #666495;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-blue.svg);
        }

        &:hover {
          color: #535185;
        }
      }
    }

    &::before {
      background-color: #e4e8f0;
    }
  }

  &.red {
    .columns-boxes .column-box-right .inner .text-eyebrow {
      color: #be562c;
    }

    .quick-links {
      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-red.svg);
        }

        &:hover {
          color: #be562c;
        }
      }
    }

    &::before {
      background-color: #f8e1d7;
    }
  }
}
