/* stylelint-disable no-descending-specificity */
.wine-page-intro {
  padding-top: 50px;
  padding-bottom: 50px;

  @include from($desktop) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .container {
    max-width: 1620px !important;
  }

  p.text-eyebrow {
    margin: 0 0 5px;

    @include from($widescreen) {
      margin: 0 0 18px;
    }
  }

  h2.text-eyebrow {
    margin: 0 0 35px;

    @include from($tablet) {
      margin: 0 0 50px;
    }

    @include from($widescreen) {
      margin: 0 0 60px;
    }
  }

  .column-image {
    .image-holder {
      position: relative;
      z-index: 32;

      svg {
        content: '';
        position: absolute;
        top: 36%;
        transform: translateY(-36%);
        left: 0;
        width: 65%;
        z-index: -1;
      }
    }

    img {
      margin: 0 auto;
    }
  }

  .column-text {
    .column.is-half {
      @include from($widescreen) {
        padding-right: 3.5rem;
      }

      &:last-child {
        @include from($widescreen) {
          padding-right: 0.75rem;
        }
      }
    }
  }
}

.half-bgr-section {
  .boxes {
    position: relative;
    z-index: 32;

    .left-box {
      width: 100%;

      @include from($tablet) {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
      }

      img {
        @include from($tablet) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
        }
      }
    }

    .right-box {
      width: 100%;
      padding: 30px 20px 40px;

      @include from($tablet) {
        margin-left: auto;
        width: 50%;
        padding: 45px 20px 55px 45px;
      }

      @include from($widescreen) {
        padding: 90px 20px 100px 90px;
      }

      .inner {
        max-width: 550px;
        width: 100%;

        p {
          color: $white-color;
        }
      }
    }
  }
}

.wine-page-info {
  padding-top: 75px;
  padding-bottom: 35px;

  @include from($widescreen) {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .wine-page-info-header {
    margin-bottom: 40px !important;

    .h2 {
      margin: 0 0 35px;
    }
  }

  .wine-page-info-wrap {
    .wine-page-info-left,
    .wine-page-info-right {
      width: 100%;

      @include from($tablet) {
        width: calc(100% - 120px);
      }

      @include from($widescreen) {
        width: calc(100% - 300px);
      }
    }

    .wine-page-info-left {
      @include from($tablet) {
        padding-right: 40px;
      }

      @include from($widescreen) {
        padding-right: 70px;
      }

      .item {
        margin-bottom: 35px;

        .h5 {
          margin: 0 0 5px;
        }
      }
    }

    .wine-page-info-middle {
      width: 140px;
      margin: 0 auto 35px;
      position: relative;
      z-index: 32;

      @include from($tablet) {
        flex-shrink: 0;
        width: 120px;
        margin: 0 auto;
      }

      @include from($widescreen) {
        width: 300px;
      }

      img {
        display: block;
        margin: 0 auto;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 130%;

        @include from($tablet) {
          width: 110%;
        }

        @include from($widescreen) {
          width: 115%;
        }
      }
    }

    .wine-page-info-right {
      @include from($tablet) {
        padding-left: 40px;
      }

      @include from($widescreen) {
        padding-left: 70px;
      }

      ul {
        li {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(195, 177, 147, 0.35);
          padding: 16px 0;

          @include from($tablet) {
            justify-content: flex-start;
          }

          .h5 {
            font-size: 11px;
            line-height: 1.4em;
            letter-spacing: 0.075em;
            margin: 0;
            width: 150px;
            flex-shrink: 0;
          }

          span {
            font-size: 14px;
            line-height: 1.4em;

            @include from($tablet) {
              font-size: 15px;
            }

            @include from($widescreen) {
              font-size: 17px;
            }
          }

          &:last-of-type {
            border-bottom: 0 none;
          }
        }
      }
    }
  }
}
