.no-list-style {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.is-family-primary {
  font-family: $font-tagline !important;
}

.is-family-secondary {
  font-family: $font-title !important;
}

.is-family-code {
  font-family: $font-text !important;
}

.is-column-centered {
  margin: 0 auto !important;
}

.is-rounded {
  border-radius: 50% !important;
}

.is-radius-10 {
  border-radius: 10px !important;
}

.bgr-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bgr-style {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.bgr-left-center {
    background-position: left center;
  }

  &.bgr-right-center {
    background-position: right center;
  }

  &.bgr-top-center {
    background-position: top center;
  }

  &.bgr-bottom-center {
    background-position: bottom center;
  }
}

.richtext-image.left {
  width: 40%;
  float: left;
  margin-right: 20px;
}

.richtext-image.right {
  width: 40%;
  float: right;
  margin-left: 20px;
}

.richtext-image.full-width {
  width: 100%;
  float: right;
  margin-bottom: 25px;
  display: block;
}

.blk.block-text li,
.blk.block-text ul li {
  margin-left: 20px;
  margin-bottom: 20px;
}

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
