.social-sidebar {
  @include from($desktop) {
    position: absolute;
    top: 35px;
    left: -20%;
  }

  ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    padding: 0 !important;

    @include from($desktop) {
      flex-direction: column;
    }

    li {
      display: block;
      margin: 0 10px 0 0 !important;

      @include from($desktop) {
        margin: 0 0 15px !important;
      }
    }

    li a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border: 1px solid $main-color;
      background-color: transparent;
      border-radius: 50%;
      transition: all 0.2s linear;

      svg {
        display: block;
        height: 14px !important;

        path {
          fill: $main-color !important;
          transition: all 0.2s linear;
        }
      }

      &:hover {
        border: 1px solid $main-color;
        background-color: $main-color;
      }

      &:hover svg path {
        fill: $white-color !important;
      }
    }
  }
}
