.social-share {
  p {
    font-size: 18px;
    line-height: 1em;
    color: #898989;
    margin: 0;
  }

  .social {
    li {
      margin-left: 15px;

      @include from($widescreen) {
        margin-left: 20px;
      }
    }

    li a {
      display: block;

      svg {
        display: block;
        height: 22px !important;

        path {
          fill: #898989 !important;
          transition: all 0.2s linear;
        }
      }

      &:hover svg path {
        fill: $main-color !important;
      }
    }
  }
}
