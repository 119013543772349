// Some style for style-guide page
.clearfix {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

body {
  font-family: $font-text;
  font-weight: 400;
  color: $text-color;
  background: $white-color;
}

.style-guide {
  padding: 50px 0;

  .container {
    width: calc(100% - 40px);
  }

  .style-guide-title {
    font-size: 24px;
    line-height: 1em;
    font-weight: 700;
    text-decoration: underline;
    color: $black-color;
    margin: 0 0 10px;
  }

  .style-guide-colors {
    margin-left: -20px;

    li {
      margin: 0 0 20px 20px;
      width: calc(50% - 20px);

      @include from($tablet) {
        width: calc((100% / 3) - 20px);
      }

      @include from($widescreen) {
        width: calc(20% - 20px);
      }

      p {
        font-size: 16px;
        line-height: 1em;
        margin: 0 0 5px;
      }

      span {
        display: block;
        width: 100%;
        height: 25px;
      }
    }
  }

  .style-guide-buttons {
    .btn {
      margin: 5px 5px 5px 0;
    }
  }
}

@include until($tablet) {
  .container.is-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
