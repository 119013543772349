.recipe-description-section {
  padding-top: 55px;
  padding-bottom: 55px;

  @include from($tablet) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .recipe-description-section-inner {
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 75px 0 45px;
    position: relative;

    @include from($tablet) {
      padding: 65px 0;
    }

    @include from($widescreen) {
      padding: 95px 0;
    }

    .recipe-description-section-top-icon {
      position: absolute;
      top: -46px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .recipe-description-left-image {
    position: relative;
    margin-bottom: 30px;

    @include from($tablet) {
      margin-bottom: 0;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;
      max-width: 340px;
      margin: 0 auto;
    }

    img {
      margin: 0 auto;
    }
  }

  .recipe-description-right {
    ul {
      li {
        display: block;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        .h5 {
          margin: 0 0 5px;
        }
      }
    }
  }
}
