/* stylelint-disable no-descending-specificity */
.latest-news-section {
  padding-top: 0;
  padding-bottom: 0;

  .container {
    border-top: 1px solid #d6d6d6;
    padding-top: 55px;
    padding-bottom: 90px;

    @include from($tablet) {
      padding-top: 70px;
      padding-bottom: 100px;
    }

    @include from($widescreen) {
      padding-top: 100px;
      padding-bottom: 120px;
    }
  }

  .h1 {
    margin: 0 0 35px;

    @include from($tablet) {
      margin: 0 0 45px;
    }

    @include from($widescreen) {
      margin: 0 0 65px;
    }
  }

  .columns.is-multiline {
    margin-bottom: 30px;

    @include from($tablet) {
      margin-bottom: 50px;
    }
  }

  .column {
    margin-bottom: 15px;

    &:nth-child(3n+3) {
      padding-right: 0.75rem !important;
    }

    .box-item {
      border-bottom: 1px solid #f1e7db;
      padding-bottom: 25px;
      height: 100%;

      @include from($desktop) {
        padding-bottom: 35px;
      }

      .box-item-content {
        .text-eyebrow {
          margin: 0 0 5px;
        }

        .h5 {
          a {
            &:hover {
              // text-decoration: underline;
              color: $hover-color;
            }
          }
        }
      }

      .box-item-image {
        a {
          display: block;
          width: 61px;
          height: 61px;

          @include from($desktop) {
            width: 92px;
            height: 92px;
          }

          img {
            display: block;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
