/* stylelint-disable no-descending-specificity */
.hero-logo-section {
  min-height: 500px;

  @include from($tablet) {
    min-height: 540px;
  }

  @include from($desktop) {
    min-height: 600px;
  }

  .container {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include from($tablet) {
      min-height: 540px;
    }

    @include from($desktop) {
      min-height: 600px;
    }
  }

  .columns {
    width: 100%;
  }

  .hero-full-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .bgr-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient($main-color 0%, rgba(49, 44, 40, 0) 100%);
    opacity: 0.58;
    z-index: 1;
  }

  .inner {
    z-index: 3;
    padding: 90px 0;

    .hero-logo {
      margin: 0 auto 60px;
    }
  }

  &.smaller {
    min-height: 380px;

    @include from($tablet) {
      min-height: 405px;
    }

    .container {
      min-height: 360px;

      @include from($tablet) {
        min-height: 405px;
      }
    }

    .inner {
      padding: 90px 0;

      .hero-logo {
        margin: 0 auto;
      }
    }
  }
}
