/* stylelint-disable no-descending-specificity */
.hero-search-section {
  padding: 0;
  min-height: 100vh;
  position: relative;
  z-index: 32;

  @include from($tablet) {
    min-height: 768px;
  }

  @include from($desktop) {
    min-height: 653px;
  }

  .bgr-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient($main-color 0%, rgba(49, 44, 40, 0) 100%);
    opacity: 0.42;
  }

  .container {
    padding: 0 0.75rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include from($tablet) {
      min-height: 768px;
    }

    @include from($desktop) {
      padding: 0 1.5rem;
      min-height: 653px;
    }
  }

  .columns {
    width: 100%;
  }

  .tagline {
    font-weight: 700;
    margin: 0 0 32px;

    @include from($widescreen) {
      margin: 0 0 42px;
    }
  }

  .form-wrap {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .input-search-icon {
      position: absolute;
      top: 13px;
      left: 15px;

      @include from($widescreen) {
        top: 19px;
        left: 25px;
      }
    }

    .form-wrap-inner {
      display: flex;
      flex-direction: column;

      @include from($tablet) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .form-wrap-input {
        width: 100%;
        margin-bottom: 15px;

        @include from($tablet) {
          width: calc(100% - 215px);
          margin-bottom: 0;
        }

        @include from($widescreen) {
          width: calc(100% - 326px);
        }
      }

      .form-wrap-button {
        width: 100%;

        @include from($tablet) {
          width: 200px;
        }

        @include from($widescreen) {
          width: 311px;
        }
      }
    }

    input[type='search'] {
      display: block !important;
      height: 55px !important;
      line-height: 55px !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      letter-spacing: 0.075em !important;
      color: $main-color !important;
      text-transform: uppercase !important;
      background-color: $white-color !important;
      width: 100% !important;
      padding: 0 20px 0 70px !important;

      @include from($widescreen) {
        font-size: 16px !important;
        height: 67px !important;
        line-height: 67px !important;
        padding: 0 20px 0 80px !important;
      }
    }

    input::-webkit-input-placeholder {
      color: #aaa !important;
    }

    input:-moz-placeholder {
      color: #aaa !important;
    }

    input::-moz-placeholder {
      color: #aaa !important;
    }

    input:-ms-input-placeholder {
      color: #aaa !important;
    }

    button[type='submit'] {
      width: 100%;
      padding: 21px 10px;

      @include from($widescreen) {
        padding: 26px 10px;
      }

      &:hover {
        background-color: $main-color;
      }

      &:focus {
        outline: 0;
      }
    }

    .form-wrap-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: $white-color;
      border-top: 1px solid #d5d5d5;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
      width: 100%;
      text-align: left;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background: $white-color;
        z-index: -1;
      }

      ul {
        padding: 22px 20px 22px 70px;
        border-bottom: 1px solid #d5d5d5;

        @include from($widescreen) {
          padding: 22px 20px 22px 80px;
        }

        li {
          display: block;
          font-size: 13px;
          line-height: 1.6em;
          font-weight: 400;
          letter-spacing: 0.075em;
          text-transform: uppercase;
          margin-bottom: 22px;
          color: $seventh-color;

          @include from($tablet) {
            font-size: 14px;
            line-height: 1.6875em;
          }

          @include from($desktop) {
            font-size: 15px;
          }

          @include from($fullhd) {
            font-size: 16px;
            line-height: 1.684em;
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-weight: 700;
          }
        }
      }

      .form-wrap-dropodown-label {
        padding: 22px 20px 22px 70px;
        position: relative;

        @include from($widescreen) {
          padding: 22px 20px 22px 80px;
        }

        svg {
          display: block;
          position: absolute;
          top: 25px;
          left: 25px;
          width: 17px !important;
          height: 17px !important;

          @include from($tablet) {
            top: 27px;
            left: 25px;
          }

          @include from($widescreen) {
            left: 32px;
          }

          @include from($fullhd) {
            top: 29px;
            left: 33px;
          }
        }
      }
    }
  }

  &.with-image {
    min-height: initial !important;
    position: relative;
    background-color: $accent-color;

    @include from($desktop) {
      background-color: transparent;
    }

    .bgr-overlay {
      display: none;

      @include from($desktop) {
        display: block;
        z-index: 1;
      }
    }

    .columns {
      width: calc(100% + 1.5rem);
    }

    .container {
      min-height: initial !important;
      padding-top: 50px;
      padding-bottom: 50px;

      @include from($desktop) {
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }

      h1 {
        color: $text-color !important;

        @include from($desktop) {
          color: $white-color !important;
        }
      }

      form {
        input:not([type="submit"]),
        select {
          width: 100% !important;
          font-size: 15px !important;
          font-weight: 700 !important;
          background-color: $white-color !important;
          text-transform: uppercase !important;
          height: 50px !important;
          line-height: 50px !important;

          @include from($tablet) {
            font-size: 16px !important;
          }

          @include from($widescreen) {
            height: 60px !important;
            line-height: 60px !important;
          }

          @include from($fullhd) {
            font-size: 19px !important;
          }
        }

        input:not([type="submit"]) {
          text-overflow: ellipsis;
        }

        button[type="submit"] {
          padding: 18px 10px;

          @include from($widescreen) {
            padding: 23px 10px;
          }
        }
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
