/* stylelint-disable no-descending-specificity */
.filter-data-section-wrap {
  .open-filter {
    display: inline-block;
    font-size: 14px;
    line-height: 1.4em;
    font-weight: 500;
    color: $sixth-color;
    border: 0 none;
    padding: 0;
    background: none;
    cursor: pointer;

    @include from($tablet) {
      display: none;
    }

    &:focus {
      outline: 0;
    }

    &::after {
      content: '';
      border: solid $sixth-color;
      border-width: 0 2px 2px 0;
      display: inline-block;
      vertical-align: middle;
      padding: 3px;
      transform: rotate(45deg);
      margin: -5px 0 0 5px;
    }
  }
}

.filter-data-section {
  display: none;

  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white-color;
    z-index: 999;
    display: none;
  }

  @include from($tablet) {
    display: block !important;
  }

  &.active {
    display: block;
  }

  form {
    width: 100%;
    height: 100%;
  }

  .filter-data-section-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid $sixth-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;

    p {
      font-size: 19px;
      line-height: 1em;
      color: $sixth-color;
    }

    .close-filter {
      display: inline-block;
      position: relative;
      width: 23px;
      height: 23px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $sixth-color;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .filter-data-section-body {
    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 55px;
      left: 0;
      width: 100%;
      height: calc(100vh - 155px);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  .expand-filter-box {
    display: block;
    font-size: 15px;
    line-height: 1.4em;
    font-weight: 600;
    color: $black-color;
    border: 0 none;
    background: none;
    cursor: pointer;
    position: relative;
    margin: 0;
    text-align: left;
    width: 100%;
    padding: 24px 1.5rem;

    @include from($tablet) {
      padding: 0;
      display: inline-block;
      font-size: 19px;
      margin-bottom: 20px;
    }

    &:focus {
      outline: 0;
    }

    .req {
      color: #be562c;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 26px;
      right: 1.5rem;
      background-image: url(../images/icon-plus.svg);
      background-size: 16px 16px;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;

      @include from($tablet) {
        display: none;
      }
    }

    &.active::before {
      top: 30px;
      background-image: url(../images/icon-minus.svg);
      background-size: 16px 1px;
      height: 1px;
    }
  }

  .filter-data-box {
    position: relative;
    border-bottom: 1px solid $sixth-color;

    @include from($tablet) {
      border-bottom: 0 none;
      margin-bottom: 40px;
    }

    .filter-data-box-inner {
      display: none;
      padding: 0 1.5rem 30px;

      @include from($tablet) {
        display: block;
        padding: 0;
      }

      &.active {
        display: block;
      }
    }

    .fields {
      display: flex;
      flex-wrap: wrap;

      @include from($tablet) {
        display: block;
      }
    }

    .field {
      display: flex;
      align-items: center;
      margin: 0 10px 10px 0;

      @include from($tablet) {
        margin: 0 0 20px;
      }

      label {
        display: block;
        font-size: 15px;
        line-height: 1.4em;
        font-weight: 500;
        color: $sixth-color;
        padding: 5px 12px;
        margin: 0;
        border: 1px solid $sixth-color;

        @include from($tablet) {
          font-size: 19px;
          font-weight: 400;
          color: $main-color !important;
          border: 0 none !important;
          padding: 0 0 0 52px !important;
          background-color: transparent !important;
        }

        &::before {
          flex-shrink: 0;
          top: -5px;
          width: 34px;
          height: 34px;
          border-radius: 4px;
          background-color: $accent-color;
          border: 0 none;
          display: none;

          @include from($tablet) {
            display: inline-block;
          }
        }

        &:hover::before {
          border: 0 none;
        }

        &::after {
          width: 7px;
          height: 12px;
          border: solid $white-color;
          border-width: 0 2px 2px 0;
          top: 8px;
          left: 12px;

          @include from($tablet) {
            top: 2px;
            width: 9px;
            height: 15px;
          }
        }
      }

      input:checked ~ label {
        padding: 5px 12px 5px 30px;
        border: 1px solid $second-color;
        background-color: $second-color;
        color: $white-color;
      }

      input:checked ~ label::before {
        background-color: $second-color;
      }
    }

    .select {
      display: block;
      width: 100%;

      select {
        color: $sixth-color !important;
      }
    }

    .filter-date {
      input {
        color: $sixth-color !important;
      }

      input::-webkit-input-placeholder {
        color: $sixth-color !important;
      }

      input:-moz-placeholder {
        color: $sixth-color !important;
      }

      input::-moz-placeholder {
        color: $sixth-color !important;
      }

      input:-ms-input-placeholder, {
        color: $sixth-color !important;
      }
    }

    .clear-button {
      margin-top: 25px;
      display: none;

      @include from($tablet) {
        display: inline-block;
      }

      a {
        display: inline-block;
        font-size: 19px;
        line-height: 1em;
        font-weight: 400;
        color: $main-color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .filter-data-section-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem 25px;

    @include from($tablet) {
      display: none;
    }

    button {
      display: block;
      width: 48%;

      &.is-primary {
        border: 2px solid $second-color;

        &:hover {
          border: 2px solid $main-color;
        }
      }
    }
  }
}
