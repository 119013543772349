/* stylelint-disable no-descending-specificity */
.block-sherryweek_registration {
  .section {
    padding-top: 55px;
    padding-bottom: 15px;

    @include from($tablet) {
      padding-top: 65px;
    }

    @include from($desktop) {
      padding-top: 75px;
    }

    @include from($fullhd) {
      padding-top: 92px;
    }

    .columns {
      position: relative;
      padding-bottom: 55px;

      //&::after {
      //  content: '';
      //  position: absolute;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //  margin: 0 auto;
      //  width: calc(100% - 1.5rem);
      //  border-bottom: 1px solid #d6d6d6;
      //}

      .h2 {
        margin: 0 0 34px;
      }

      .h6 {
        margin: 0 0 30px;
      }

      blockquote {
        display: block;
        font-size: 18px;
        line-height: 1.6em;
        font-style: italic;
        border-left: 1px solid $main-color;
        color: $main-color;
        padding: 15px 25px;
        text-align: left;
        margin: 25px 0;

        @include from($tablet) {
          font-size: 20px;
        }

        @include from($fullhd) {
          font-size: 22px;
        }
      }
    }

    .social-share {
      margin-top: 40px;

      @include from($tablet) {
        margin-top: 70px;
      }

      @include from($desktop) {
        margin-top: 90px;
      }

      @include from($fullhd) {
        margin-top: 130px;
      }

      .social {
        padding-left: 0;

        p {
          letter-spacing: 0.75em;
        }

        li {
          margin-left: 20px;

          @include from($widescreen) {
            margin-left: 25px;
          }
        }

        li a {
          svg {
            height: 24px !important;

            path {
              fill: #a00010 !important;
            }
          }

          &:hover svg path {
            fill: $main-color !important;
          }
        }
      }
    }

    .sidebar-box-image {
      margin: 0 auto 20px;
      border-radius: 0;
    }

    .sidebar-box-form {
      background-color: #f09c18 !important;
      position: relative;
      z-index: 32;
      margin-top: 35px;
      margin-bottom: 0;
      border: 10px solid #fff383;
      border-radius: 0;

      @include from($tablet) {
        margin-top: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 364px;
        //background-image: url(../images/img-form-circles.png);
        //background-repeat: no-repeat;
        //background-position: top center;
        //background-size: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: -1;
      }

      .h3 {
        font-family: $font-bodega;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;

        @include from($tablet) {
          font-size: 40px;
        }

        @include from($fullhd) {
          font-size: 54px;
        }
      }
    }

    .registration {
      .registration-inner {
        border-radius: 10px;
        padding: 40px 20px;
        background-color: #fefbeb;

        @include from($desktop) {
          padding: 55px 30px;
        }

        @include from($widescreen) {
          padding: 70px 35px;
        }

        p {
          a {
            color: var(--campaign-menu-primary);

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .button {
          max-width: 260px;
          width: 100%;
          background-color: var(--campaign-button-primary) !important;
          padding-left: 10px !important;
          padding-right: 10px !important;

          &:hover {
            background-color: $main-color !important;
          }
        }
      }
    }
  }
}

.block-wide_image_link {
  .section {
    padding-top: 0;
    padding-bottom: 0;

    .boxes {
      position: relative;
      z-index: 32;
      padding-top: 0;
      padding-bottom: 40px;

      @include from($tablet) {
        padding-top: 80px;
        padding-bottom: 30px;
      }

      .box-content {
        width: 100%;

        @include from($tablet) {
          width: 40%;
        }

        @include from($desktop) {
          width: 50%;
        }

        h2,
        .h1 {
          font-family: $font-bodega;
        }

        h2 {
          font-size: 40px;
          line-height: 1em;
          margin: 0 0 5px;

          @include from($tablet) {
            font-size: 50px;
          }

          @include from($desktop) {
            font-size: 60px;
          }

          @include from($widescreen) {
            font-size: 72px;
          }

          @include from($fullhd) {
            font-size: 90px;
          }

          @media only screen and (min-width: 1680px) {
            font-size: 107px;
          }

          a {
            color: #3c1202;

            &:hover {
              color: $main-color;
            }
          }
        }

        .h1 {
          font-size: 24px;

          @include from($tablet) {
            font-size: 32px;
          }

          @include from($desktop) {
            font-size: 36px;
          }

          @include from($widescreen) {
            font-size: 42px;
          }

          @include from($fullhd) {
            font-size: 62px;
          }
        }
      }

      .box-image {
        margin-bottom: 20px;

        @include from($tablet) {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          max-width: 80%;
          z-index: -1;
          margin-bottom: 0;
        }

        @include from($desktop) {
          max-width: 100%;
        }

        @include from($widescreen) {
          right: 1%;
        }

        @include from($fullhd) {
          right: 2%;
        }

        a {
          display: block;
          height: 100%;

          @include from($tablet) {
            height: 100%;
          }

          img {
            display: block;
            border-radius: 0 !important;
            margin: 0 auto;

            @include from($tablet) {
              margin: 0;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

.block-testimonials {
  .section {
    padding-top: 0;
    padding-bottom: 0;

    .columns {
      padding-bottom: 40px;

      @include from($tablet) {
        padding-bottom: 50px;
        align-items: center;
      }

      @include from($desktop) {
        padding-bottom: 60px;
      }

      @include from($widescreen) {
        padding-bottom: 80px;
      }
    }

    .column {
      img {
        width: 100%;
      }

      blockquote {
        border-left: 1px solid $main-color;
      }

      cite {
        display: inline-block;
        text-transform: none;
        font-size: 14px;
        line-height: 1.4em;
        font-weight: 300;
        margin-top: 15px;

        @include from($tablet) {
          font-size: 15px;
        }

        @include from($desktop) {
          font-size: 15px;
          margin-top: 27px;
        }

        @include from($fullhd) {
          font-size: 17px;
        }
      }
    }
  }
}

.section-inline-logos {
  padding-top: 20px;
  padding-bottom: 20px;

  .columns {
    position: relative;
    padding-top: 35px;

    @include from($tablet) {
      padding-top: 45px;
    }

    @include from($desktop) {
      padding-top: 50px;
    }

    @include from($fullhd) {
      padding-top: 80px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: calc(100% - 1.5rem);
      border-bottom: 1px solid #d6d6d6;
    }
  }

  .inline-logos {
    flex-wrap: wrap;
    margin-bottom: 25px;

    @include from($tablet) {
      flex-wrap: nowrap;
    }

    @include from($desktop) {
      margin-bottom: 40px;
    }

    li {
      padding: 12px;
      width: calc(100% / 3);

      img {
        display: block;
        margin: 5px 15px;
      }

      @include from($tablet) {
        padding: 12px 20px;
        width: auto;
      }

      @include from($desktop) {
        padding: 12px 30px;
      }

      @include from($widescreen) {
        padding: 12px 55px;
      }
    }

    li a {
      display: block;

      img {
        display: block;
        margin: 5px 15px;
      }
    }
  }

  .text-base {
    @include from($fullhd) {
      font-size: 18px;
    }
  }
}

.section-hero-about {
  padding-top: 0;
  padding-bottom: 0;
  height: 100vh;
  background-color: $ninth-color;
  overflow: hidden;

  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }

  .hero-box {
    position: absolute;
    top: 95px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 32;

    @include from($tablet) {
      .shader {
        text-shadow: 2px 2px 5px #fff, 2px 2px 5px #fff;
      }
    }

    h1,
    h2 {
      font-family: $font-bodega;
      line-height: 1em;
      letter-spacing: 0.01em;
      font-weight: 500;
    }

    h1 {
      font-size: 70px;

      @include from($tablet) {
        font-size: 130px;
      }

      @include from($desktop) {
        font-size: 180px;
      }

      @include from($widescreen) {
        font-size: 222px;
      }

      span {
        color: #f9e16d;
        font-size: 70%;
      }
    }

    h2 {
      font-size: 26px;
      letter-spacing: 0.025em;
      color: var(--campaign-menu-primary);
      margin: -15px 0 0;

      @include from($tablet) {
        font-size: 30px;
      }

      @include from($desktop) {
        font-size: 36px;
        margin: -20px 0 0;
      }

      @include from($widescreen) {
        font-size: 40px;
        margin: -30px 0 0;
      }
    }
  }

  .hero-box-image {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    .img-circles {
      display: block;
      // width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      margin: 0 auto;

      @include from($desktop) {
        height: calc(100% + 200px);
      }
    }

    .img-hero {
      width: 100%;
      height: calc(100% + 200px);

      @include from($desktop) {
        width: 80%;
      }

      display: block;
      margin: 0 auto;
    }
  }
}

.section-hero-about-small {
  background-color: $ninth-color;
  padding-top: 0;
  padding-bottom: 0;

  .boxes {
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  .box-content {
    padding: 100px 0 40px;

    @include from($desktop) {
      padding: 120px 0 50px;
    }

    @include from($widescreen) {
      padding: 130px 0 60px;
    }

    @include from($fullhd) {
      padding: 140px 0 70px;
    }

    @media only screen and (min-width: 1700px) {
      padding: 150px 0 80px;
    }

    h1,
    h2 {
      font-family: $font-bodega;
      line-height: 1em;
      letter-spacing: 0.01em;
      font-weight: 500;
    }

    h1 {
      font-size: 60px;

      @include from($tablet) {
        font-size: 80px;
      }

      @include from($desktop) {
        font-size: 110px;
      }

      @include from($widescreen) {
        font-size: 146px;
      }

      @include from($fullhd) {
        font-size: 158px;
      }

      @media only screen and (min-width: 1700px) {
        font-size: 206px;
      }

      span {
        color: #f9e16d;
        font-size: 70%;
      }
    }

    h2 {
      font-size: 22px;
      letter-spacing: 0.025em;
      color: var(--campaign-menu-primary);
      margin: -15px 0 0;

      @include from($tablet) {
        font-size: 28px;
      }

      @include from($desktop) {
        font-size: 32px;
        margin: -20px 0 0;
      }

      @include from($widescreen) {
        font-size: 36px;
      }

      @include from($fullhd) {
        font-size: 40px;
        margin: -30px 0 0;
      }
    }
  }

  .box-image {
    max-width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;

    @include from($desktop) {
      right: 1%;
    }

    @include from($widescreen) {
      right: 2%;
    }

    @include from($fullhd) {
      right: 4%;
    }

    img {
      display: block;
    }
  }
}

.sherryweek-page {
  .header {
    background-color: transparent !important;

    .header-logo-primary {
      svg path {
        fill: $main-color;
        stroke: $main-color;
      }
    }

    .header-search {
      svg path {
        stroke: $main-color;
      }
    }

    .header-navigation {
      background-color: transparent;
    }

    .navigation-menu {
      > li > a {
        color: $main-color;
      }
    }

    .header-lang span {
      color: $main-color;
    }

    .header-logo-secondary {
      .header-logo-secondary-white {
        display: none;
      }

      .header-logo-secondary-colored {
        display: block;
      }
    }

    &:hover,
    &.sticky {
      background-color: $main-color !important;

      .header-navigation {
        background-color: $main-color;
      }

      .header-logo-primary {
        svg path {
          fill: $white-color;
          stroke: $white-color;
        }
      }

      .header-search {
        svg path {
          stroke: $white-color;
        }
      }

      .navigation-menu {
        > li > a {
          color: $white-color;
        }
      }

      .header-lang span {
        color: #cbb293;
      }

      .header-logo-secondary {
        .header-logo-secondary-white {
          display: block;
        }

        .header-logo-secondary-colored {
          display: none;
        }
      }
    }
  }

  .top-space {
    padding-top: 0;
  }
}

.campaign-5 .navbar-custom,
.campaign-5 .navbar-custom ul {
  background-color: var(--campaign-menu-primary) !important;
}

.campaign-5 .navbar-custom ul a {
  color: var(--campaign-menu-secondary) !important;
}

.campaign-5 .navbar-custom a {
  color: var(--campaign-menu-secondary) !important;
}

body.campaign-5 .section .button {
  background-color: var(--campaign-button-primary) !important;
}

.campaign-5 a {
  color: var(--campaign-button-primary);
}

.campaign-5 .blog-post-content a {
  color: #863104;
  text-decoration: underline;
}

//.campaign-5 .navbar-custom .navbar-custom-menu .navbar-menu-list li  {
//  color: white !important;
//}

@media only screen and (max-width: 1023px) {
  .navbar-custom .navbar-custom-open {
    background-color: var(--campaign-menu-primary) !important;
    color: var(--campaign-menu-secondary) !important;
  }
}

@media only screen and (max-width: 1023px) {
  .campaign-5 .navbar-custom .navbar-custom-wrap .navbar-custom-menu,
  .campaign-5 .navbar-custom .navbar-custom-menu .navbar-menu-list ul {
    background-color: var(--campaign-menu-primary) !important;
  }

  .campaign-5 .navbar-custom {
    background-color: var(--campaign-menu-primary) !important;
  }

  .campaign-5 .navbar-custom a {
    color: #fef559 !important;
  }

  .campaign-5 .navbar-custom .navbar-custom-menu .navbar-menu-list > li {
    border-bottom-color: #fef559 !important;
  }

  .page-7300 h2.h3,
  .page-7301 h2.h3 {
    width: calc(100% - 180px);
    hyphens: none;
  }
}

.navbar-menu-list > li:nth-child(2) > a {
  color: var(--campaign-menu-secondary) !important;
}

.campaign-5 .navbar-custom a.active {
  color: var(--campaign-menu-secondary) !important;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list > li > a.active::before {
  visibility: visible !important;
  opacity: 1;
  height: 2px;
}

.campaign-5 .navbar-custom .navbar-custom-menu .navbar-menu-list > li:nth-child(1) {
  background: var(--campaign-menu-secondary) !important;
  color: var(--campaign-menu-primary) !important;
}

.campaign-5 .navbar-custom .navbar-custom-menu .navbar-menu-list > li:nth-child(1) a {
  color: var(--campaign-menu-primary) !important;
  display: inline-block;
  padding-right: 20px;
  padding-left: 20px;
}

.navbar-custom {
  padding: 0 1.5rem;
  background-color: var(--campaign-menu-primary);
}

.navbar-custom .navbar-custom-open,
.navbar-custom .navbar-custom-close {
  display: none;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list > li {
  margin: 0 23px;
  position: relative;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list li a {
  display: inline-block;
  color: var(--campaign-menu-secondary);
  padding: 32px 0;
  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  position: relative;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list > li > a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 25px;
  width: 100%;
  height: 0;
  background: var(--campaign-menu-secondary);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list > li > a:hover::before,
.navbar-custom .navbar-custom-menu .navbar-menu-list > li:hover > a::before {
  height: 2px;
  opacity: 1;
  visibility: visible;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  display: none;
  z-index: 5;
  background-color: var(--campaign-menu-primary);
}

.navbar-custom .navbar-custom-menu .navbar-menu-list ul.active {
  display: block;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list ul li {
  display: block;
  text-align: center;
}

.navbar-custom .navbar-custom-menu .navbar-menu-list ul li a {
  display: block;
  padding: 20px 15px;
}

.no-touch .navbar-custom .navbar-custom-menu .navbar-menu-list ul li a:hover {
  color: #2b1b0d;
}

.no-touch .navbar-custom .navbar-custom-menu .navbar-menu-list > li:hover ul {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .navbar-custom .navbar-custom-wrap .navbar-custom-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: var(--campaign-menu-primary);
  }

  .navbar-custom .navbar-custom-wrap .navbar-custom-menu.active {
    display: block;
  }

  .navbar-custom .navbar-custom-close {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .navbar-custom .navbar-custom-open {
    background-color: var(--campaign-menu-primary);
    display: block;
    width: 100%;
    padding: 20px 1.5rem;
    color: var(--campaign-menu-secondary);
    text-align: center;
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }

  .navbar-custom .navbar-custom-wrap .navbar-custom-menu .navbar-menu-list {
    padding-top: 95px;
    display: block;
  }

  .navbar-custom .navbar-custom-menu .navbar-toggle-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    font-size: 24px;
    font-weight: 600;
    color: var(--campaign-menu-secondary);
  }

  .navbar-custom .navbar-custom-menu .navbar-toggle-dropdown::before {
    content: '+';
    position: absolute;
  }

  .navbar-custom .navbar-custom-menu .navbar-toggle-dropdown.active::before {
    content: '-';
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list ul {
    position: initial;
    top: initial;
    left: initial;
    transform: none;
    width: 100%;
    display: block;
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list ul li {
    text-align: left;
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list ul li a {
    padding: 15px 1.5rem 15px 2.2rem;
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list > li {
    margin: 0;
    border-bottom: 1px solid var(--campaign-menu-primary);
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list > li > a {
    display: block;
    padding: 20px 64px 20px 1.5rem;
  }

  .navbar-custom .navbar-custom-menu .navbar-menu-list > li > a::before {
    display: none;
  }

  #main-lang {
    display: none;
  }

  #main-lang-container ul {
    display: flex;
    border-top: 1px solid var(--campaign-menu-secondary);
  }

  #main-lang-container ul li {
    width: 50%;
    text-align: center;
  }

  #main-lang-container ul li a {
    padding-left: 1.5rem !important;
  }
}

.campaign-4 .ml-3 {
  color: #2b1b0d !important;
}

@media only screen and (max-width: 1023px) {
  .navbar-custom .navbar-custom-open {
    background-color: var(--campaign-menu-primary) !important;
  }
}

body.campaign-5 .button.is-outlined {
  color: #2b1b0d !important;
}
