.breadcrumb {
  margin-bottom: 10px;
  font-size: 9px;
  line-height: 1.35em;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: $seventh-color;
  text-transform: uppercase;

  @include from($tablet) {
    font-size: 11px;
  }

  a,
  span {
    display: inline-block;
    padding: 0;
  }

  a {
    color: $seventh-color;

    &:hover {
      text-decoration: underline;
    }
  }
}
