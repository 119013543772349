/* stylelint-disable no-descending-specificity */
.pagination-custom {
  ul {
    width: 100%;

    li {
      margin: 0 5px 15px;
    }

    li a {
      display: inline-block;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      color: $black-color;
      text-align: center;

      @include from($desktop) {
        font-size: 21px;
        line-height: 43px;
      }

      &.page-number {
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 4px;

        @include from($desktop) {
          width: 43px;
          height: 43px;
        }
      }

      &.current {
        background-color: $second-color;
        color: $white-color;
        pointer-events: none;
      }

      &.page-dots {
        pointer-events: none;
      }

      &.prev,
      &.next {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $accent-color;

        @include from($desktop) {
          width: 43px;
          height: 43px;
        }

        svg {
          display: block;
          width: 6px !important;
          height: 14px !important;

          @include from($desktop) {
            width: 9px !important;
            height: 17px !important;
          }
        }

        &.disabled {
          background-color: transparent;
          pointer-events: none;
        }
      }

      &.prev {
        margin-right: 20px;

        @include from($desktop) {
          margin-right: 20px;
        }
      }

      &.next {
        margin-left: 20px;

        @include from($desktop) {
          margin-left: 30px;
        }
      }
    }
  }
}
