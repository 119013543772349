/* stylelint-disable no-descending-specificity */
.sidebar-box {
  .sidebar-box-form {
    padding: 15px 10px 30px;
    margin-bottom: 45px;

    @include from($tablet) {
      padding: 15px 10px 35px;
    }

    @include from($widescreen) {
      padding: 25px 15px 40px;
      margin-bottom: 65px;
    }

    ._form-thank-you {
      color: #2b1b0d !important;

      @include from($fullhd) {
        font-size: 18px;
      }
    }

    p {
      margin: 0 0 25px;

      @include from($fullhd) {
        font-size: 18px;
      }
    }

    form {
      margin: 0 !important;
      padding: 0 !important;

      label[for=firstname],
      label[for=lastname],
      label[for=email] {
        display: none !important;
      }

      input:not([type='submit']) {
        display: block !important;
        height: 57px !important;
        line-height: 57px !important;
        color: $black-color !important;
        background-color: $white-color !important;
        border: 0 none !important;
        width: 100% !important;
        margin-bottom: 18px !important;

        @include from($widescreen) {
          height: 57px !important;
          line-height: 57px !important;
          padding: 0 20px !important;
        }
      }

      input:not([type='submit'])::-webkit-input-placeholder {
        color: $black-color !important;
      }

      input:not([type='submit']):-moz-placeholder {
        color: $black-color !important;
      }

      input:not([type='submit'])::-moz-placeholder {
        color: $black-color !important;
      }

      input:not([type='submit']):-ms-input-placeholder {
        color: $black-color !important;
      }
    }

    button[type="submit"] {
      font-family: $font-text !important;
      background-color: $second-color !important;
      font-size: 16px !important;
      line-height: 1em !important;
      font-weight: 700 !important;
      letter-spacing: 0.075em !important;
      color: $white-color !important;
      border-radius: 4px !important;
      border: 0 none !important;
      text-align: center !important;
      text-transform: uppercase !important;
      transition: all 0.2s linear !important;
      cursor: pointer !important;
      display: block !important;
      width: 100% !important;
      padding: 21px 10px !important;
      margin-top: 25px !important;

      @include from($widescreen) {
        padding: 26px 10px !important;
        margin-top: 35px !important;
      }

      &:focus {
        outline: 0 !important;
      }

      &:hover {
        background-color: $main-color !important;
      }
    }

    ._row._checkbox-radio {
      position: relative !important;
      margin-bottom: 18px !important;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      label {
        display: block !important;
        font-size: 14px !important;
        line-height: 1.5em !important;
        font-weight: 400 !important;
        color: $black-color !important;
        padding-left: 35px !important;
        position: relative;

        @include from($widescreen) {
          font-size: 15px !important;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::before {
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          background-color: $white-color;
          border-radius: 4px;
          z-index: 1;
          display: block;
        }

        &::after {
          width: 0.375rem;
          height: 0.6rem;
          top: 0.375rem;
          left: 0.6rem;
          box-sizing: border-box;
          transform: translateY(0) rotate(45deg);
          border-width: 0.1rem;
          border-style: solid;
          border-color: $white-color;
          border-top: 0;
          border-left: 0;
          z-index: 1;
          display: none;
        }
      }

      input:checked ~ span label::before {
        background-color: $main-color;
      }

      input:checked ~ span label::after {
        display: inline-block;
      }
    }

    legend._form-label {
      font-family: $font-text !important;
      display: block !important;
      font-size: 14px !important;
      line-height: 1.5em !important;
      font-weight: 400 !important;
      color: $black-color !important;
      margin-bottom: 10px !important;

      @include from($widescreen) {
        font-size: 15px !important;
      }
    }

    ._form-branding {
      display: none !important;
    }

    ._error._below {
      position: initial !important;
      padding: 0 !important;
      margin-top: -8px !important;

      ._error-arrow {
        display: none !important;
      }

      ._error-inner {
        background: transparent !important;
        padding: 0 !important;
        font-family: $font-text !important;
        color: #f00 !important;
        text-align: right !important;
      }
    }
  }

  .sidebar-box-link-more {
    a {
      display: inline-block;
      font-size: 14px;
      line-height: 1.3em;
      letter-spacing: 0.075em;

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }

      @include from($widescreen) {
        font-size: 16px;
      }
    }
  }

  .sidebar-box-makers {
    margin-bottom: 50px;

    @include from($tablet) {
      margin-bottom: 65px;
    }

    @include from($widescreen) {
      margin-bottom: 90px;
    }

    .h3 {
      margin: 0 0 27px;

      @include from($widescreen) {
        margin: 0 0 42px;
      }
    }

    .sidebar-box-makers-list {
      border-bottom: 1px solid #d6d6d6;
      padding-bottom: 30px;
      margin-bottom: 30px;

      ul {
        li {
          &.img-wrap {
            width: 60px;
            flex-shrink: 0;

            @include from($widescreen) {
              width: 80px;
            }

            img {
              display: block;
              width: 60px;
              height: 60px;
              border-radius: 50%;

              @include from($widescreen) {
                width: 80px;
                height: 80px;
              }
            }
          }

          &.text-wrap {
            width: calc(100% - 60px);
            padding-left: 15px;

            @include from($widescreen) {
              width: calc(100% - 80px);
              padding-left: 20px;
            }

            .h5 {
              margin: 0 0 5px;
            }

            .text-eyebrow {
              margin: 0;

              a {
                &:hover {
                  // text-decoration: underline;
                  color: $hover-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-box-post {
    margin-bottom: 45px;

    @include from($tablet) {
      margin-bottom: 65px;
    }

    @include from($widescreen) {
      margin-bottom: 95px;
    }

    .sidebar-box-post-list {
      border-radius: 10px;
      background-color: #fbf8f5;
      width: 100%;
      margin-bottom: 20px;

      .box-item-image {
        img {
          display: block;
          width: 100%;
          margin: 0 auto;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .box-item-content {
        padding: 22px 20px;

        @include from($tablet) {
          padding: 28px 28px;
        }

        @include from($widescreen) {
          padding: 40px 36px;
        }

        .text-eyebrow {
          margin: 0 0 15px;
        }

        .h4 {
          line-height: 1em;
          margin: 0;

          a {
            color: $main-color;

            &:hover {
              // text-decoration: underline;
              color: $hover-color;
            }
          }
        }
      }
    }
  }

  .sidebar-box-categories {
    margin-bottom: 45px;

    @include from($tablet) {
      margin-bottom: 55px;
    }

    @include from($widescreen) {
      margin-bottom: 75px;
    }

    .h3,
    .h4 {
      margin: 0 0 25px;
    }

    ul {
      li {
        display: block;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #d6d6d6;

        p {
          margin: 0 0 10px;
        }

        .h5 {
          margin: 0;

          a {
            color: $main-color;

            &:hover {
              // text-decoration: underline;
              color: $hover-color;
            }
          }
        }
      }
    }
  }
}
