/* stylelint-disable no-descending-specificity */
.event-list-page {
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 75px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1.5rem;
    width: calc(100% + 3rem);
    height: 1px;
    background-color: $sixth-color;
    display: block;

    @include from($tablet) {
      display: none;
    }
  }

  @include from($tablet) {
    margin-top: 0;
    padding-top: 45px;
    padding-bottom: 95px;
  }

  @include from($widescreen) {
    padding-top: 45px;
    padding-bottom: 95px;
  }

  .event-list-content {
    .box-items {
      .box-item {
        border-radius: 10px;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .box-item-inner {
          width: 100%;
          height: 100%;
        }

        .box-item-img {
          display: block;

          img {
            display: block;
            width: 100%;
            border-radius: 10px 10px 0 0;
          }
        }

        .box-item-content {
          padding: 25px 20px 15px;

          @include from($tablet) {
            padding: 25px 25px 15px;
          }

          @include from($widescreen) {
            padding: 40px 35px 20px;
          }
        }

        .h4 {
          a {
            color: $main-color;
          }
        }

        .event-type {
          font-size: 11px;

          @include from($tablet) {
            font-size: 16px;
          }
        }

        .event-tag {
          font-size: 11px;
          line-height: 1.4em;
          color: #ac895f;

          @include from($tablet) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .pagination-custom {
    margin-top: 35px;

    @include from($desktop) {
      margin-top: 45px;
    }
  }
}
