/* stylelint-disable no-descending-specificity */
.hero-recipe {
  padding-top: 40px;
  padding-bottom: 60px;

  @include from($tablet) {
    padding-top: 50px;
    padding-bottom: 75px;
  }

  @include from($widescreen) {
    padding-top: 60px;
    padding-bottom: 90px;
  }

  .text-eyebrow {
    margin: 0 0 15px;

    &.secondary {
      margin: 0 0 27px;
    }
  }
}

.recipe-section {
  padding-bottom: 90px;

  .round-image-title {
    border-top: 1px solid #d6d6d6;
    width: 100%;
    margin: 0 auto 100px;
    position: relative;

    img {
      display: block;
      position: absolute;
      top: -65px;
      left: 50%;
      transform: translateX(-50%);
      width: 129px;
      height: 129px;
      border: 1px solid #d6d6d6;
    }
  }

  .recipe-content {
    .recipe-content-main-image {
      margin-bottom: 20px;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .recipe-duration {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 25px;

      svg {
        display: block;
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }

      p {
        font-size: 14px;

        span {
          color: $main-color;
        }
      }
    }

    .recipe-content-ingredients {
      ul {
        li {
          display: block;
          font-size: 15px;
          line-height: 1.6em;
          font-weight: 400;
          color: $text-color;
          position: relative;
          margin-bottom: 15px;

          @include from($tablet) {
            font-size: 16px;
          }

          @include from($fullhd) {
            font-size: 19px;
          }

          .checkbox-label {
            display: flex;
            position: relative;
            padding-left: 25px;

            @include from($fullhd) {
              padding-left: 40px;
            }

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .checkmark {
              display: block;
              position: absolute;
              top: 6px;
              left: 0;
              width: 12px;
              height: 12px;
              border: 1px solid #b2b2b2;
              background-color: transparent;
              border-radius: 50%;
              transition: all 0.2s linear;

              @include from($tablet) {
                top: 7px;
              }

              @include from($fullhd) {
                width: 19px;
                height: 19px;
              }
            }

            input:checked ~ .checkmark {
              border: 1px solid $second-color;
              background-color: $second-color;
            }
          }
        }
      }

      .column {
        @include from($tablet) {
          padding-right: 30px;
        }

        @include from($widescreen) {
          padding-right: 60px;
        }

        &:last-child {
          padding-right: 0.75rem;
        }
      }
    }

    .separator {
      width: 100%;
      margin: 30px auto 50px;
      border-bottom: 1px solid #d6d6d6;
    }

    .recipe-content-directions {
      margin-bottom: 60px;

      ul {
        li {
          display: block;
          font-size: 15px;
          line-height: 1.6em;
          font-weight: 400;
          color: $text-color;
          position: relative;
          padding-left: 25px;
          margin-bottom: 15px;
          counter-increment: item;

          @include from($tablet) {
            font-size: 16px;
          }

          @include from($fullhd) {
            font-size: 19px;
            padding-left: 40px;
          }

          &::before {
            content: counter(item) '.';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
