/* stylelint-disable no-descending-specificity */
.blk.block-entradilla .section,
.blk.block-image .section,
.blk.block-doubleImage .section {
  padding-top: 45px;
  padding-bottom: 45px;
}

.blk.block-quotes + .blk.block-image .section.py-0 {
  padding-bottom: 45px !important;
}

.blk.block-image img + .text-caption {
  margin-top: 15px;

  @include from($widescreen) {
    margin-top: 20px;
  }
}

.blk.block-text + .blk.block-image {
  padding-bottom: 55px;
}

.blk.block-text + .blk.block-image + .blk.block-image {
  padding-bottom: 55px;
}

.blk.block-entradilla + .blk.block-image {
  padding-bottom: 55px;
}

.blk.block-image + .blk.block-text .section {
  padding-top: 0;
}

.blk.block-image + .blk.block-entradilla .section {
  padding-top: 0;
}

.blk.block-entradilla + .blk.block-text .section {
  padding-top: 0;
}

.blk.block-text + .blk.block-entradilla .section {
  padding-top: 0;
}

.blk.block-doubleImage + .blk.block-text .section {
  padding-top: 0;
}

.blk.block-doubleImage .section .container.pb-5 {
  padding-bottom: 0 !important;
}

.blk.block-coloredBackground + .blk.block-doubleImage .section.p-t-0 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.blk.block-svg .p-b-70 + .blk.block-doubleImage {
  padding-bottom: 0 !important;
}

.blk.block-quickLinks + footer {
  padding-top: 55px !important;
}

.blk.block-coloredBackground {
  padding-top: 0;
  padding-bottom: 0;

  > .pb-5 {
    padding-top: 50px !important;
    padding-bottom: 10px !important;

    .blk.block-text .section {
      padding-top: 0;
      padding-bottom: 45px;
    }

    .blk.block-doubleImage .section {
      padding-bottom: 0 !important;

      .container.pb-5 {
        padding-bottom: 55px !important;
      }
    }
  }
}

.blk img {
  border-radius: 10px;
}

.blk ol,
.blk ul {
  padding-left: 20px;
}

.blk.quick-links ul {
  padding-left: 0;
}

.blk.block-coloredBackground + .blk.block-download_list .download-list-section {
  padding-top: 0;
}

.blk.block-coloredBackground + .blk.block-download_list + .blk.block-download_list .download-list-section {
  padding-bottom: 90px;
}

.blk.block-image + .footer {
  margin-top: 55px;
}

.blk.block-text + .blk.block-text .section {
  padding-top: 0;
}

.blk.block-text + .blk.block-coloredBackground.m-t-50,
.blk.block-image + .blk.block-coloredBackground.m-t-50 {
  margin-top: 0 !important;
}
