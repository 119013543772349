.grid-gallery-section {
  padding-bottom: 75px;

  @include from($tablet) {
    padding-bottom: 90px;
  }

  @include from($widescreen) {
    padding-bottom: 120px;
  }

  .columns {
    .column {
      .grid {
        .grid-item {
          border: 1px solid #e6e6e6;
          border-radius: 10px;

          img {
            display: block;
            border-radius: 9px;
          }
        }
      }
    }
  }
}
