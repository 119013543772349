/* stylelint-disable no-descending-specificity */
.block-picture_text {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;

    .text-eyebrow {
      margin: 0 0 15px;

      @include from($desktop) {
        margin: 0 0 20px;
      }
    }

    .h2 {
      margin: 0 0 24px;

      @include from($desktop) {
        margin: 0 0 31px;
      }
    }

    .quick-links {
      margin-top: 35px;
      padding: 0;

      @include from($tablet) {
        margin-top: 45px;
      }

      @include from($desktop) {
        margin-top: 75px;
      }
    }

    .columns.Thin1,
    .columns.Thin2 {
      .column.is-5:first-child {
        @include from($tablet) {
          width: 41.66666674%;
        }
      }

      .column.is-5:last-child {
        @include from($tablet) {
          width: 58.33333337%;
          padding: 15px 0.75rem 0 30px !important;
        }

        @include from($desktop) {
          padding: 15px 0.75rem 0 45px !important;
        }

        @include from($widescreen) {
          padding: 15px 30px 0 60px !important;
        }
      }
    }
  }

  .columns.ultra {
    .column.is-5:first-child {
      @include from($tablet) {
        width: 41.66666674%;
        padding-right: 30px !important;
      }

      @include from($desktop) {
        padding-right: 45px !important;
      }

      @include from($widescreen) {
        padding-right: 60px !important;
      }
    }

    .column.is-5:last-child {
      @include from($tablet) {
        width: 58.33333337%;
        padding: 15px 0.75rem 0 0.75rem !important;
      }
    }
  }

  .custom-column.two-columns {
    column-gap: 20px;

    @include from($tablet) {
      column-gap: 30px;
    }

    @include from($desktop) {
      column-gap: 45px;
    }

    @include from($widescreen) {
      column-gap: 60px;
    }
  }
}
