/* stylelint-disable no-descending-specificity */
.wide-promo-section {
  min-height: 540px;

  @include from($desktop) {
    min-height: 684px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 540px;

    @include from($desktop) {
      min-height: 684px;
    }

    .columns {
      width: 100%;
      padding-top: 75px;
      padding-bottom: 75px;

      @include from($desktop) {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      .column {
        @media only screen and (max-width: 768px) {
          display: block;
          width: calc(100% - 1.5rem);
          margin: 0 auto;
        }
      }
    }
  }

  .inner-box {
    background-color: #814230;
    padding: 35px 20px 60px;
    border-radius: 10px;

    &.orange {
      .button {
        background-color: #d37700 !important;
        border-color: #d37700 !important;
      }

      background-color: #f7e8d6 !important;
    }

    &.purple {
      .button {
        background-color: #a25570 !important;
      }

      background-color: #efdbe2;
    }

    &.yellow {
      .button {
        background-color: #d6aa38 !important;
      }

      background-color: #faf2da;
    }

    &.green {
      .button {
        background-color: #779345 !important;
      }

      background-color: #e8efd3;
    }

    &.blue {
      .button {
        background-color: #666495 !important;
      }

      background-color: #e4e8f0;
    }

    &.red {
      .button {
        background-color: #be562c !important;
      }

      background-color: #f8e1d7;
    }

    @include from($tablet) {
      padding: 40px 30px 70px;
    }

    @include from($desktop) {
      padding: 45px 45px 75px;
    }

    img {
      margin: 0 auto 18px;
    }

    .h1 {
      margin: 0 0 40px;
    }

    .button {
      &.is-outlined {
        border: 2px solid white !important;
      }

      color: $white-color !important;

      &:hover {
        border: 2px solid $white-color !important;
        color: #814230 !important;
        background-color: $white-color !important;
      }
    }
  }
}
