/* stylelint-disable no-descending-specificity */
.recipe-list-menu-section {
  margin-bottom: 40px;

  @include from($tablet) {
    margin-bottom: 60px;
  }

  .wrapper {
    flex-direction: column;

    @include from($tablet) {
      flex-direction: row;
    }
  }

  .filter-menu {
    margin-bottom: 0;

    ul {
      li {
        a {
          text-transform: none;
        }
      }
    }
  }

  .filter-button {
    margin-top: 10px;

    @include from($tablet) {
      margin-top: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 45px;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      font-size: 15px;
      line-height: 1em;
      margin: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;

      @include from($tablet) {
        font-size: 16px;
      }

      @include from($widescreen) {
        width: 142px;
        height: 55px;
      }

      @include from($fullhd) {
        font-size: 19px;
      }

      &:focus {
        outline: 0;
      }

      span {
        display: block;
        margin: 6px 10px 0 0;
        width: 27px;
        height: 20px;
        font-size: 15px;
        line-height: 18px;

        svg {
          display: block;
          width: 27px !important;
          height: 14px !important;
        }
      }
    }
  }
}

.recipe-list-filter-section {
  margin-bottom: 25px !important;

  .select {
    &.is-selected {
      select {
        background-color: $white-color !important;
        border: 1px solid $sixth-color !important;
      }

      .label-wrap {
        a {
          display: inline-block;
        }
      }
    }
  }

  input,
  select {
    color: $sixth-color !important;
    border: 1px solid transparent !important;
  }

  input::placeholder {
    opacity: 1;
  }

  input::-webkit-input-placeholder {
    color: $sixth-color !important;
  }

  input:-moz-placeholder {
    color: $sixth-color !important;
  }

  input::-moz-placeholder {
    color: $sixth-color !important;
  }

  input:-ms-input-placeholder {
    color: $sixth-color !important;
  }

  .label-wrap {
    width: 100%;
    margin-bottom: 7px;

    @include from($widescreen) {
      margin-bottom: 15px;
    }

    label {
      display: block;
      font-size: 15px;
      line-height: 1.4em;
      font-weight: 500;

      @include from($tablet) {
        font-size: 16px;
      }

      @include from($fullhd) {
        font-size: 19px;
      }
    }

    a {
      display: none;
      font-size: 15px;
      line-height: 1.4em;
      color: #cbb293;

      @include from($tablet) {
        font-size: 16px;
      }

      @include from($fullhd) {
        font-size: 19px;
      }

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }
    }
  }

  .reset-filter {
    display: inline-block;
    font-size: 15px;
    line-height: 1.4em;
    color: #c3b193;
    margin-top: 10px;

    @include from($tablet) {
      font-size: 16px;
      margin-top: 38px;
    }

    @include from($widescreen) {
      margin-top: 52px;
    }

    @include from($fullhd) {
      font-size: 19px;
    }

    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid #c3b193;
    }

    &:hover::after {
      border-bottom: 0 none;
    }
  }

  .field {
    .icon-wrap {
      position: relative;

      input {
        padding: 0 50px 0 20px !important;

        @include from($widescreen) {
          padding: 0 60px 0 30px !important;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

        @include from($widescreen) {
          right: 30px;
        }
      }
    }
  }
}

.recipe-list-grid {
  padding-bottom: 40px;

  @include from($tablet) {
    padding-bottom: 50px;
  }

  @include from($widescreen) {
    padding-bottom: 60px;
  }

  .box-item {
    border-radius: 10px;

    .box-image {
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px 10px 0 0;
      }
    }

    .box-content {
      padding: 35px 30px;
      text-align: left !important;
    }

    &.box-item-secondary {
      .box-image {
        padding: 30px;

        img {
          width: auto;
          border-radius: 0;
        }
      }

      .box-content {
        padding: 30px 30px 40px;

        .button {
          display: block;
          max-width: 360px;
          width: 100%;
          margin: 0 auto;
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
      }
    }
  }

  .load-more-button {
    .button {
      margin: 40px auto 0;
    }
  }
}

.featured-img-links-section {
  padding: 40px 0 75px;

  @include from($tablet) {
    padding: 50px 0 90px;
  }

  @include from($widescreen) {
    padding: 60px 0 120px;
  }

  .box-items {
    a {
      display: block;

      .box-item {
        position: relative;
        z-index: 3;

        .bgr-overlay {
          z-index: 1;
          background: linear-gradient(to bottom, rgba(43, 27, 13, 0) 0%, rgba(43, 27, 13, 0.1) 62%, rgba(43, 27, 13, 0.4) 86%, rgba(43, 27, 13, 0.47) 100%);
          border-radius: 10px;
        }

        img {
          display: block;
          width: 100%;
          border-radius: 10px;
        }

        .h2 {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 0 20px 40px 40px;
          margin: 0;
          z-index: 2;
        }
      }

      &:hover .box-item .bgr-overlay {
        background: linear-gradient(to bottom, rgba(43, 27, 13, 0) 0%, rgba(43, 27, 13, 0.3) 62%, rgba(43, 27, 13, 0.5) 86%, rgba(43, 27, 13, 0.57) 100%);
      }
    }
  }
}
