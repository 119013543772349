.block-double_text {
  .section {
    padding-top: 45px;
    padding-bottom: 45px;

    /* .container.is-fluid {
      padding-left: 0;
      padding-right: 0;
    } */
  }
}
