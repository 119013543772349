/* stylelint-disable no-descending-specificity */
.home-promo {
  .orange {
    .button {
      background-color: #d37700 !important;
    }

    background-color: #f7e8d6 !important;
  }

  .purple {
    .button {
      background-color: #a25570 !important;
    }

    background-color: #efdbe2;
  }

  .yellow {
    .button {
      background-color: #d6aa38 !important;
    }

    background-color: #faf2da;
  }

  .green {
    .button {
      background-color: #779345 !important;
    }

    background-color: #e8efd3;
  }

  .blue {
    .button {
      background-color: #666495 !important;
    }

    background-color: #e4e8f0;
  }

  .red {
    .button {
      background-color: #be562c !important;
    }

    background-color: #f8e1d7;
  }

  .column.is-half {
    position: relative;

    img {
      @include from($tablet) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include from($desktop) {
  .min700 {
    min-height: 700px;
  }
}

@include from($fullhd) {
  .min700 {
    min-height: 800px;
  }
}
