/* stylelint-disable no-descending-specificity */
.gallery-slider-section {
  padding: 15px 0;

  .gallery-slider-wrap {
    position: relative;
    margin-bottom: 8px;

    @include from($widescreen) {
      margin-bottom: 25px;
    }

    .gallery-slider-controls {
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 0 none;
        border-radius: 4px;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $accent-color;
        z-index: 4;
        transition: all 0.2s linear;
        cursor: pointer;

        @include from($desktop) {
          width: 43px;
          height: 43px;
        }

        &:hover {
          outline: 0;
          background-color: #fcf2e7;
        }

        svg {
          display: block;
          width: 9px !important;
          height: 17px !important;

          @include from($desktop) {
            width: 11px !important;
            height: 19px !important;
          }
        }

        &.gallery-slider-prev {
          left: 0;

          @include from($desktop) {
            left: -55px;
          }

          @include from($widescreen) {
            left: -75px;
          }
        }

        &.gallery-slider-next {
          right: 0;

          @include from($desktop) {
            right: -55px;
          }

          @include from($widescreen) {
            right: -75px;
          }
        }

        &[disabled] {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }

    .gallery-slider {
      display: none;

      &.tns-slider {
        display: block;
      }

      .slideshow {
        img {
          display: block;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }

  .gallery-slider-pager {
    display: none;

    &.tns-slider {
      display: flex;
    }

    .slideshow {
      img {
        display: block;
        width: 100%;
        border-radius: 10px;
        border: 3px solid transparent;
        transition: all 0.2s linear;

        @include from($widescreen) {
          border: 5px solid transparent;
        }
      }

      &.tns-nav-active img {
        border: 3px solid $second-color;

        @include from($widescreen) {
          border: 5px solid $second-color;
        }
      }
    }
  }
}
