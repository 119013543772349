/* stylelint-disable no-descending-specificity */
.tagline,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-title;
  font-weight: 700;
  color: $main-color;
  margin: 0 0 15px;
}

.tagline {
  font-family: $font-tagline;
  font-size: 50px;
  letter-spacing: -0.05em;
  font-weight: 400;
  word-spacing: 0.1em;
  line-height: 1.1em;

  @include from($tablet) {
    font-size: 60px;
  }

  @include from($fullhd) {
    font-size: 75px;
  }
}

h1,
.h1 {
  font-size: 40px;
  line-height: 1.35em;

  @include from($tablet) {
    font-size: 50px;
  }

  @include from($fullhd) {
    font-size: 62px;
    line-height: 1.32em;
  }
}

h2,
.h2 {
  font-size: 30px;
  line-height: 1.3em;

  @include from($tablet) {
    font-size: 40px;
  }

  @include from($fullhd) {
    font-size: 55px;
    line-height: 1.29em;
  }
}

h3,
.h3 {
  font-size: 26px;
  line-height: 1.23em;

  @include from($tablet) {
    font-size: 30px;
    line-height: 1.33em;
  }

  @include from($fullhd) {
    font-size: 45px;
    line-height: 1.22em;
  }
}

h4,
.h4 {
  font-size: 18px;
  line-height: 1em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 26px;
    line-height: 1em;
  }

  @include from($fullhd) {
    font-size: 34px;
    line-height: 1em;
  }
}

h5,
.h5 {
  font-size: 16px;
  line-height: 1.166em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 22px;
    line-height: 1.3em;
  }

  @include from($fullhd) {
    font-size: 26px;
    line-height: 1.4em;
  }
}

h6,
.h6 {
  font-size: 16px;
  line-height: 1.166em;
  font-weight: 500;

  @include from($tablet) {
    font-size: 20px;
    line-height: 1.3em;
  }

  @include from($fullhd) {
    font-size: 24px;
    line-height: 1.4em;
  }
}

p,
.text-base {
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 400;
  color: $text-color;
  margin: 0 0 16px;

  @include from($tablet) {
    font-size: 16px;
    line-height: 1.6875em;
  }

  @include from($fullhd) {
    font-size: 19px;
    line-height: 1.684em;
  }

  &.text-xl {
    font-size: 18px;
    line-height: 1.666em;
    font-weight: 500;

    @include from($fullhd) {
      font-size: 24px;
      line-height: 1.583em;
    }
  }
}

.blk.block-text .section {
  ul,
  ol {
    list-style-position: inside;

    li {
      font-size: 15px;
      line-height: 1.6em;
      font-weight: 400;
      color: $text-color;
      margin: 0 0 16px;

      @include from($tablet) {
        font-size: 16px;
        line-height: 1.6875em;
      }

      @include from($fullhd) {
        font-size: 19px;
        line-height: 1.684em;
      }
    }
  }

  ul {
    list-style-type: disc;
  }
}

.text-xl,
.text-xl p {
  font-size: 18px;
  line-height: 1.666em;
  font-weight: 500;

  @include from($fullhd) {
    font-size: 24px;
    line-height: 1.583em;
  }
}

.text-eyebrow {
  font-family: $font-text;
  font-size: 8px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: $second-color;

  @include from($tablet) {
    font-size: 11px;
  }

  @include from($fullhd) {
    font-size: 14px;
  }

  &.secondary {
    color: $sixth-color;
  }

  &.third {
    color: $eight-color;
  }
}

a.text-eyebrow {
  color: $second-color !important;

  &:hover {
    // text-decoration: underline;
    color: $hover-color;
  }
}

.text-caption {
  font-family: $font-text;
  font-size: 9px;
  line-height: 1.4em;
  font-weight: 300;
  font-style: italic;
  color: $text-color;

  @include from($tablet) {
    font-size: 11px;
  }

  @include from($fullhd) {
    font-size: 13px;
  }
}

.quick-links {
  li {
    display: block;
    margin-bottom: 20px;

    @include from($fullhd) {
      margin-bottom: 25px;
    }
  }

  li a {
    display: inline-block;
    font-family: $font-title;
    font-size: 18px;
    line-height: 1.3em;
    font-weight: 500;
    color: $text-color;
    position: relative;
    padding-left: 27px;
    transition: all 0.2s linear;

    @include from($tablet) {
      font-size: 20px;
      padding-left: 42px;
    }

    @include from($fullhd) {
      font-size: 26px;
      padding-left: 50px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      width: 15px;
      height: 11px;
      background-image: url(../images/icon-arrow-right.svg);
      background-size: 15px 11px;
      background-repeat: no-repeat;

      @include from($tablet) {
        width: 23px;
        height: 16px;
        background-size: 23px 16px;
      }

      @include from($fullhd) {
        top: 9px;
        width: 26px;
        height: 18px;
        background-size: 26px 18px;
      }
    }

    &:hover {
      color: $second-color;
    }
  }
}

.quick-link {
  display: inline-block;
  font-family: $font-title;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 500;
  color: $text-color;
  position: relative;
  padding-left: 27px;
  transition: all 0.2s linear;

  @include from($tablet) {
    font-size: 20px;
    padding-left: 42px;
  }

  @include from($fullhd) {
    font-size: 26px;
    padding-left: 50px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 15px;
    height: 11px;
    background-image: url(../images/icon-arrow-right.svg);
    background-size: 15px 11px;
    background-repeat: no-repeat;

    @include from($tablet) {
      width: 23px;
      height: 16px;
      background-size: 23px 16px;
    }

    @include from($fullhd) {
      top: 9px;
      width: 26px;
      height: 18px;
      background-size: 26px 18px;
    }
  }

  &:hover {
    color: $second-color;
  }
}

.block-heading {
  .section {
    padding: 40px 0.75rem;

    @include from($tablet) {
      padding: 55px 0.75rem;
    }

    @include from($widescreen) {
      padding: 65px 0.75rem;
    }
  }
}

.blog-post-content ul,
.blog-post-content ol {
  padding-bottom: 20px;
}

.blog-post-content ul li,
.blog-post-content ol li,
.true-post-content ul li,
.true-post-content ol li {
  font-size: 15px;

  @include from($tablet) {
    font-size: 16px;
  }

  @include from($fullhd) {
    font-size: 19px;
  }

  padding-bottom: 10px;
  margin-left: 40px;
}

.blog-post-content ol li,
.true-post-content ol li {
  list-style: dot;
}

.blog-post-content ul li,
.true-post-content ul li {
  list-style: disc;
}

.blog-post-content blockquote p,
.true-content blockquote p {
  font-size: 18px;
  line-height: 1.6em;
  color: $main-color;

  @include from($tablet) {
    font-size: 21px;
  }

  @include from($fullhd) {
    font-size: 24px;
  }
}

.blog-post-content blockquote {
  margin: 40px 0;
}

.blog-post-content blockquote,
.true-content blockquote {
  font-size: 18px;
  line-height: 1.6em;
  font-style: italic;
  border-left: 2px solid #2b2112;
  color: $main-color;
  padding: 15px 25px;
  text-align: left;

  @include from($tablet) {
    font-size: 21px;
  }

  @include from($fullhd) {
    font-size: 24px;
  }
}

.true-content blockquote cite {
  font-size: 13px;
  color: $main-color;
  text-transform: uppercase;
  font-style: italic;
  display: block;
}

figcaption {
  font-size: 13px;
  font-style: italic;
}
