.button {
  display: inline-block;
  font-family: $font-text;
  font-size: 12px !important;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  background-color: $third-color !important;
  color: $white-color !important;
  border-radius: 4px;
  border: 0 none;
  text-align: center;
  transition: all 0.2s linear;
  padding: 16px 58px !important;
  height: initial !important;
  white-space: normal;

  @include from($tablet) {
    font-size: 14px;
    padding: 18px 68px !important;
  }

  @include from($widescreen) {
    font-size: 16px !important;
    padding: 24px 86px !important;
  }

  &:hover {
    color: $white-color !important;
    background-color: $main-color !important;
  }

  &.is-primary {
    background-color: $second-color !important;
    color: $white-color !important;

    &:hover {
      background-color: $main-color !important;
    }
  }

  &.is-outlined {
    border: 2px solid $main-color !important;
    background-color: transparent !important;
    color: $main-color !important;

    &:hover {
      border: 2px solid $main-color !important;
      color: $white-color !important;
      background-color: $main-color !important;
    }
  }
}
