/* stylelint-disable no-descending-specificity */
.block-video_links {
  .video-content {
    .open-video-modal {
      display: block;
      z-index: 1;

      .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: rgba(211, 119, 0, 0.29);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s linear;

        @include from($desktop) {
          width: 100px;
          height: 100px;
        }

        @include from($fullhd) {
          width: 129px;
          height: 129px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 17px 0 17px 30px;
          border-color: transparent transparent transparent $main-color;

          @include from($desktop) {
            border-width: 20px 0 20px 34px;
          }

          @include from($fullhd) {
            border-width: 24px 0 24px 40px;
          }
        }
      }

      img {
        border-radius: 10px 10px 0 0;
      }

      &:hover .video-icon {
        background-color: rgba(211, 119, 0, 0.49);
      }
    }

    .inner {
      padding: 30px 20px;
      border-radius: 0 0 10px 10px;

      @include from($tablet) {
        padding: 35px 25px;
      }

      @include from($widescreen) {
        padding: 40px;
      }

      p {
        color: $white-color;
      }
    }
  }

  .video-links {
    .text-eyebrow {
      margin: 0 0 30px;

      @include from($tablet) {
        margin: 0 0 40px;
      }

      @include from($widescreen) {
        margin: 0 0 55px;
      }
    }

    .quick-links {
      li {
        display: block;
        margin-bottom: 20px;

        @include from($tablet) {
          margin-bottom: 25px;
        }

        @include from($fullhd) {
          margin-bottom: 35px;
        }
      }

      li a {
        &::before {
          background-image: url(../images/icon-arrow-right-brown.svg);
        }

        &:hover {
          // color: $main-color;
          // text-decoration: underline;
          color: $hover-color;
        }
      }
    }
  }
}
