/* stylelint-disable no-descending-specificity */
input:not([type="submit"]),
textarea,
select {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  font-family: $font-text !important;
  background-color: $accent-color !important;
  border: 0 none !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 0 20px !important;
  height: 45px !important;
  line-height: 45px !important;
  box-shadow: none !important;
  color: $main-color !important;

  @include from($tablet) {
    font-size: 16px !important;
  }

  @include from($widescreen) {
    padding: 0 30px !important;
    height: 55px !important;
    line-height: 55px !important;
  }

  @include from($fullhd) {
    font-size: 19px !important;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

textarea {
  line-height: 1.5em !important;
  height: 196px !important;
  padding: 20px !important;

  @include from($widescreen) {
    padding: 20px 30px !important;
  }
}

select {
  appearance: none;
  background-image: url(../images/icon-select-dropdown.svg);
  background-repeat: no-repeat;
  background-size: 18px 9px;
  background-position: right 20px center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 40px 0 20px !important;

  @include from($widescreen) {
    background-position: right 30px center;
    padding: 0 50px 0 30px !important;
    height: 55px !important;
    line-height: 55px !important;
  }
}

select::-ms-expand {
  opacity: 0;
}

.select {
  position: relative;
  height: auto !important;

  &::after {
    display: none !important;
  }
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #aaa !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #aaa !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aaa !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  // color: rgba(43, 27, 13, 0.26);
  color: #aaa !important;
}

input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

label {
  .required {
    color: $third-color;
  }
}

.field {
  input[type="radio"] + label {
    width: 100%;
    display: flex;
    padding: 0 0 0 40px;
    margin: 0 0 16px;

    &::before,
    &::after {
      width: 24px;
      height: 24px;
    }

    &::before {
      border: 0 none !important;
      background-color: $accent-color !important;
    }

    &::after {
      transform: scale(1);
      background: $main-color;
    }
  }

  input[type="checkbox"] + label {
    width: 100%;
    display: flex;
    padding: 0 0 0 40px;
    margin: 0 0 16px;

    &::before {
      width: 24px;
      height: 24px;
      border: 0 none !important;
      background-color: $accent-color !important;
    }

    &::after {
      border-color: $white-color !important;
    }
  }

  input[type="checkbox"]:checked ~ label::before {
    background-color: $main-color !important;
  }
}

.uploader {
  border-radius: 4px !important;
  text-align: center;

  .file-uploads {
    .has-text-weight-bold {
      font-weight: 500 !important;
    }
  }
}

input[type="submit"],
input[type="reset"],
button[type="submit"],
button[type="reset"] {
  font-family: $font-text;
  display: inline-block;
  background-color: $second-color !important;
  font-size: 16px;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.075em;
  color: $white-color !important;
  border-radius: 4px;
  border: 0 none;
  text-align: center;
  text-transform: uppercase;
  // padding: 26px 10px;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    background-color: $main-color !important;
  }

  &:focus {
    outline: 0;
  }
}
