/* stylelint-disable no-descending-specificity */
.litepicker {
  font-family: $font-text;

  .container__months {
    background-color: $accent-color;
    box-shadow: none;
    border-radius: 0 0 4px 4px;
    color: $black-color;
  }

  .month-item {
    padding: 5px 5px 15px;
  }

  .month-item-header {
    font-size: 14px;
    font-weight: 600;
    color: $black-color;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -5px;
      bottom: 0;
      width: calc(100% + 10px);
      height: 1px;
      background-color: rgba(195, 177, 147, 0.39);
    }

    strong {
      font-weight: 600;
    }

    .button-previous-month,
    .button-next-month {
      background-color: #f7ebdf;
      width: 26px;
      height: 26px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 7px;
        height: 15px;
        display: block;
        background-image: url(../images/icon-calendar-prev.svg);
        background-size: 7px 15px;
        background-repeat: no-repeat;
      }

      svg {
        display: none !important;
      }
    }

    .button-next-month::before {
      background-image: url(../images/icon-calendar-next.svg);
    }
  }

  .month-item-weekdays-row {
    > div {
      padding: 14px 0 7px !important;
      font-size: 13px !important;
      line-height: 1em;
      font-weight: 600;
      color: $black-color;
    }
  }

  .container__days {
    > div {
      padding: 7px 0 !important;
      font-size: 13px !important;
      line-height: 1em;
      font-weight: 400;
      color: $black-color;
    }

    .day-item:hover {
      box-shadow: inset 0 0 0 1px $second-color;
      color: $second-color;
    }

    .day-item.is-start-date {
      background-color: $second-color;
      color: $white-color;
      border-radius: 4px;
    }

    .day-item.is-end-date {
      background-color: $second-color;
      color: $white-color;
      border-radius: 4px;
    }

    .day-item.is-in-range {
      background-color: rgba(211, 119, 0, 0.27);
    }

    .day-item.is-today {
      color: $black-color;
    }
  }

  .container__predefined-ranges {
    display: none !important;
  }
}
