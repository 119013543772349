/* stylelint-disable no-descending-specificity */
.blog-list-section {
  padding-top: 45;
  padding-bottom: 45px;

  .filter-menu-wrap {
    .filter-menu {
      margin-top: 45px;

      ul {
        li {
          a {
            text-transform: none;
          }
        }
      }
    }
  }

  .recipe-list-grid {
    width: 100%;

    .box-content {
      text-align: left !important;
    }
  }

  .grid {
    .grid-primary {
      border-bottom: 1px solid #f1e7db;
      padding-bottom: 25px;

      @include from($desktop) {
        padding-bottom: 35px;
      }

      .grid-primary-image {
        display: block;
        margin-bottom: 15px;
      }
    }

    .grid-secondary {
      border-bottom: 1px solid #f1e7db;
      padding-bottom: 25px;
      height: 100%;

      @include from($desktop) {
        padding-bottom: 35px;
      }

      .grid-secondary-image {
        a {
          display: block;
          width: 61px;
          height: 61px;

          @include from($desktop) {
            width: 92px;
            height: 92px;
          }
        }
      }
    }

    .text-eyebrow {
      margin: 0 0 5px;
    }

    img {
      display: block;
      border-radius: 10px;
    }

    a {
      color: $main-color;

      &:hover {
        // text-decoration: underline;
        color: $hover-color;
      }
    }
  }
}
